.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
}
.login-btn1 {
  margin-right: 0;
  border-radius: 20px !important;
  height: 42px;
  width: 100%;
  margin-top: 1rem;
  padding: 0 135px !important;
}
/* .login-btn{
    margin-right: 0;
    width: 100%;
    border-radius: 27px !important;
    height: 42px;
    margin-top: 1rem;
} */
.forget-btn2 {
  margin-right: 0;
  border-radius: 27px !important;
  height: 42px;
  width: 100%;
  margin-top: 1rem;
  padding: 0 11.06rem;
}
.forget-btn1 {
  margin-right: 0;
  border-radius: 27px !important;
  height: 42px;
  width: 100%;
  margin-top: 1rem;
  padding: 0 11.06rem !important;
}
.reset-pw {
  width: 497px !important;
}

@media (max-width: 767px) {
  .login-form {
    padding: 0px 15px;
  }
  .login-btn1 {
    padding: 0 120px !important;
  }
}
