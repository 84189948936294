/* Developments */
.developments .col-md-2 {
  width: 200px;
  padding: 0;
}
.developments .col-md-10 {
  width: calc(100% - 200px);
  padding: 0;
}

/* Developments Left */
.image-box {
  border-bottom: 1px solid rgba(105, 105, 105, 0.32);
  margin: 0 8px 65px;
}
.person-name {
  font-family: "Roboto", sans-serif;
  line-height: 19px;
  color: #4f4f4f;
  margin-top: 16px;
}
.fabric-manager {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}
.maharani-india {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #696969;
  margin-top: 3px;
  margin-bottom: 16px;
}
.developments-left .nav .nav-link {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: #4f4f4f;
  padding: 0 8px 19px 16px;
}
.developments-left .nav .active,
.developments-left .nav .nav-link:hover {
  font-weight: 700;
  color: #2f80ed;
}

/* Developments Right */
.developments-right {
  background-color: #f2f2f2;
}
.developments-right .nav-top {
  background-color: #e0e0e0;
  padding-left: 58px;
}
.nav-top .nav-link {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.5);
  padding: 7px 20px 4px;
  margin-right: 75px;
}
.nav-top .active,
.nav-top .nav-link:hover {
  color: #000000;
  border-bottom: 2px solid #000000;
}
/* Order */
.order {
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 18px 32px 16px;
  padding: 16px;
}
.text-16 {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #333333;
  margin-bottom: 16px;
}
.text-14,
.view-more,
.latest-stage,
.quantity,
.submission-date,
.responses {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #828282;
  margin-bottom: 12px;
}
.text-14 span {
  font-weight: 500;
  color: #4f4f4f;
  margin-left: 8px;
}
.weave-oxford {
  margin-bottom: 46px;
}
.colour-blue {
  margin-bottom: 5px;
  font-style: italic;
}
.colour-blue span {
  margin-left: 11px;
  font-style: normal;
}
.view-more {
  font-weight: 500;
  color: #2f80ed;
  text-decoration: underline;
  margin-right: 12px;
}
.latest-stage {
  font-weight: 500;
  color: #333333;
  margin-bottom: 17px;
  margin-left: 28px;
}
.latest-stage span {
  width: 180px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.02em;
  color: #ffffff;
  background-color: #d35c6a;
  border-radius: 8px;
  margin-left: 4px;
  display: inline-block;
  text-align: center;
}
.enquiries-view .weft-warp {
  margin-top: 35px;
}
.construction {
  margin: 0 32px;
}
.dimensions {
  margin-top: 21px;
}
.dimensions .text-14 {
  text-decoration: underline;
  margin-bottom: 8px;
}
.quantity {
  font-weight: 500;
  color: #4f4f4f;
  margin-bottom: 5px;
}
.quantity span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
  margin: 0 6px;
}
.target-price {
  margin: 0 75px;
}
.width {
  margin-left: 45px;
}
.submission-date {
  color: #4f4f4f;
  margin-bottom: 15px;
  margin-right: 4px;
}
.submission-date .text-16 {
  color: #4f4f4f;
  margin: 0 9px;
}
.submission-date .delete-button img {
  margin-top: -4px;
}
.responses {
  margin-bottom: 8px;
}
.responses-purple,
.unseen-red,
.seen-green {
  font-weight: 600;
  color: #9b51e0;
  margin-left: 8px;
  margin-right: 16px;
}
.unseen-red {
  color: #eb5757;
}
.seen-green {
  color: #27ae60;
}
.view-button,
.go-back a {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #ffffff;
  background-color: #2f80ed;
  border-radius: 4px;
  padding: 8px 59px;
  display: inline-block;
  margin-top: 21px;
}
.view-button:hover {
  color: #ffffff;
}

/* Enquries View */
.enquiries-view {
  margin: 32px;
}
.enquiries-view .submission-date {
  margin-right: 15px;
}

/* Order View */
.order-view {
  border-top: 0.5px solid #4f4f4f;
  margin-top: 16px;
}
.mills {
  margin-top: 16px;
}
.mills .img-box {
  width: 109px;
}
.mills .img-box img {
  margin-top: -3px;
}
.mills-title {
  font-weight: 500;
  color: #4f4f4f;
  margin-bottom: 0;
  margin-right: 11px;
  margin-top: -2px;
}
.border-between {
  border-left: 0.5px solid #828282;
  height: 70px;
}
.gold-stars {
  padding-left: 8px;
  padding-top: 2px;
}
.gold-star,
.green-star {
  color: #f2994a;
  font-size: 17px;
}
.green-star {
  color: #27ae60;
}
.gold-stars .text-14 {
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 0;
  margin-top: -2px;
}
.blue-green {
  margin-top: 25px;
}
.price {
  margin-bottom: 8px;
  text-decoration: underline;
}
.payment-terms {
  margin-right: 91px;
}
.mills .view-button {
  margin-top: 22px;
}
.go-back a {
  color: #2f80ed;
  background-color: #ffffff;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px 48px;
  margin-top: 192px;
}
.go-back a:hover {
  background-color: #2f80ed;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}
.sampling-view .go-back a {
  margin-top: 338px;
}
.confirmation-view .go-back a {
  margin-top: 484px;
}

/* View More button */
.view-more-button {
  padding-bottom: 24px;
  max-height: 200px;
}
.view-more-button .text-16 {
  margin-bottom: 41px;
}
.white-color {
  color: #ffffff;
}
.view-more-button .colour-blue {
  margin-bottom: 10px;
}
.view-more-button .print-red {
  margin-top: 1px;
  margin-bottom: 11px;
}
.view-more-button .dimensions {
  margin-top: 35px;
}
.view-more-button .quantity {
  margin-bottom: 10px;
}
.view-more-button .close-button {
  margin-top: -10px;
  margin-right: -3px;
}
#show-view-more {
  display: none;
}

.text-20,
.mills-text-16,
.mills-text-14 {
  font-family: "Lato", sans-serif;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #333333;
}
.mills-text-16,
.mills-text-14 {
  line-height: 19px;
  color: #828282;
}
.mills-text-14 {
  font-size: 14px;
  line-height: 17px;
}
.text-18,
.mills-text-16 span,
.mills-text-14 span {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-left: 8px;
}
.border-line {
  border-bottom: 0.5px solid #828282;
  max-width: 666px;
}
.text-underline {
  text-decoration: underline;
}
.margin-l40 {
  margin-left: 40px;
}
.margin-l16 {
  margin-left: 16px;
}

/* Detailed RFQ Response */
.vardhman-right {
  max-width: 878px;
}
.maharani-box {
  background: #f2f2f2;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.maharani-heading {
  background-color: #e0e0e0;
  border-radius: 10px 10px 0px 0px;
  padding: 8px 0 7px;
  margin-bottom: 0;
}
.detailed-response {
  padding: 33px 30px;
}
.warp {
  margin: 0 16px 10px;
}
.count span {
  margin-left: 47px;
}
.comments span {
  margin-left: 18px;
}
.print-fabric {
  font-weight: 600;
  color: #333333;
}
.fabric-customize .mills-text-16 {
  font-weight: 500;
  color: #333333;
  margin-top: 8px;
}
.orange-box {
  background: rgba(242, 153, 74, 0.8);
  border-radius: 10px;
  padding: 8px;
  margin-left: 64px;
  margin-top: 10px;
}
.orange-box .mills-text-16 {
  font-weight: 600;
  color: #333333;
  margin: 0 0 10px 0;
}
.orange-box .mills-text-14 {
  font-weight: 500;
  color: #333333;
}
.response-140 {
  background: rgba(242, 153, 74, 0.8);
  border-radius: 10px;
  font-weight: 600;
  color: #333333;
  padding: 8px 44px 8px 8px;
  margin-left: 247px;
}
.response-140 span {
  margin-left: 15px;
}
.fabric-customize .numbering {
  margin-top: 17px;
  margin-right: 4px;
}
.fabric-customize .numbering .mills-text-16 {
  font-weight: 400;
  color: #828282;
}
.fabric-customize .quantity {
  text-align: end;
  margin-left: 25px;
  margin-right: 31px;
}
.dyed-fabric {
  margin-left: 68px;
}
.colour-code {
  margin-left: 44px;
  margin-right: 45px;
}
.dyed-quantity {
  text-align: end;
}
.order-details {
  margin-bottom: 21px;
}
.order-details .dyed {
  margin-left: 63px;
}
.delivery-to {
  font-weight: 400 !important;
  color: #828282 !important;
  margin: 0 4px !important;
}

/* Seller Response */
.seller-response {
  background: #ffffff;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 32px 16px 42px;
}
.seller-response .text-18 {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  margin-left: 0;
}
.seller-response .mills-text-16 {
  color: #4f4f4f;
}
.seller-response .form-control {
  font-family: "Lato", sans-serif;
  width: 100px;
  height: 48px;
  padding: 8px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #c4c4c4 !important;
  border: 0.5px solid #000000;
}
.seller-response #Comments {
  width: 325px;
  height: 80px;
  resize: none;
}
.seller-response .form-control:focus {
  box-shadow: none;
  border-color: #000;
}
.seller-response .form-group ::placeholder {
  color: #c4c4c4;
}
.seller-response .inr {
  margin-left: 8px;
}
.seller-response .delivery-date {
  margin: 0 8px;
}
.seller-response #Payment {
  margin-left: 31px;
}
.response-button {
  height: 32px;
}
.seller-response .button-bottom {
  margin-right: 0;
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
}
.seller-response .button-bottom:focus {
  box-shadow: none;
}

/* Fill Information */
.fill-information {
  box-shadow: none;
  border: 1px solid #f2994a;
  border-radius: 4px;
  padding: 16px 8px 15px 16px;
  margin-top: 16px;
}
.fill-information #PaymentTerms {
  width: 193px;
  margin-left: 16px;
}
.fill-information #Comments {
  width: 308px;
  height: 48px;
}
.fill-information .optional-comments {
  margin-left: 25px;
}

/* Response Box */
.response-box {
  background: #f2f2f2;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 250px;
  margin-top: 29px;
}
.response-box h6 {
  color: #4f4f4f;
  background-color: #e0e0e0;
  margin-bottom: 0;
  text-align: center;
  padding: 6px 0 7px;
  border-radius: 10px 10px 0px 0px;
}
.response-box table thead th {
  font-weight: 400;
  color: #333333;
  padding: 4px 0 0;
  text-align: center;
}
.response-box thead .print-response {
  font-weight: 600;
  color: #2f80ed;
  border-bottom: 2px solid #2f80ed !important;
  padding: 4px 0 0;
}
.response-box table tbody th {
  font-weight: 400;
  text-align: right;
  padding: 7px 8px 8px;
  background: #ffffff;
  border-radius: 0 !important;
  border: 1px solid #f2f2f2 !important;
  border-right: 2px solid #f2f2f2 !important;
  width: 50%;
}
.response-box table tbody td {
  font-weight: 600;
  color: #4f4f4f;
  padding: 7px 8px 8px;
  background: #ffffff;
  border-radius: 0 !important;
  border: 1px solid #f2f2f2 !important;
  word-break: break-all;
  width: 50%;
}

.proceed-sampling,
.proceed-order,
.send-swatch,
.request-labdip,
.go-back {
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #2f80ed;
  background: #ffffff;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  width: 250px;
  text-align: center;
  padding: 7px 0;
  margin-top: 16px;
}
.proceed-sampling:hover,
.proceed-order:hover,
.send-swatch:hover,
.go-back:hover {
  background: #2f80ed;
  color: #ffffff !important;
  text-decoration: none;
}
.send-swatch,
.go-back {
  width: 150px;
  margin: 19px 0 13px 4px;
}
.request-labdip,
.proceed-sampling,
.proceed-order {
  color: #ffffff;
  background: #2f80ed;
  border: none;
  width: 275px;
  margin: 24px 16px 0 0;
}
.request-labdip:hover,
.proceed-sampling:hover,
.proceed-order:hover {
  color: #ffffff !important;
  text-decoration: none;
}
.proceed-sampling {
  width: 250px;
  margin: 48px 0 0;
  padding: 8px 0;
}
.proceed-order {
  width: 250px;
  margin: 16px 0 48px;
  padding: 8px 0;
}

/* Labdip */
.labdip {
  padding: 16px;
  height: calc(100% - 48px);
}
.labdip .mills-text-14 {
  font-weight: 500;
}
.add-new a {
  font-family: Roboto;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #2f80ed;
  text-decoration: underline;
}
.labdip-request {
  background: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 450px;
  padding: 16px;
  margin-top: 10px;
}
.labdip-comments .form-control {
  border-color: #828282;
  resize: none;
  height: 50px;
}
.labdip-comments .btn-primary {
  width: 150px;
  height: 32px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
}
.labdip-comments .submit-btn-primary{
  height: 32px;
  margin-bottom: 16px;
}
.labdip-buttons .view-invoice {
  font-weight: 500;
  color: #2f80ed;
  text-decoration: underline;
}
.margin-l21 {
  margin-left: 21px !important;
}
.margin-b9 {
  margin-bottom: 9px !important;
}