.admin-table {
  border: 1px solid rgba(128, 128, 128, 0.015);
  /* border-radius: 12px; */
  margin-top: 5vh !important;
}
.table {
  padding: 3vh !important;
}
.heading-row {
  font-size: 1.65vh;
  background: #e0e0e0;
  text-align: center;
}
.admin-table th {
  height: 4vh;
  min-width: 12vw !important;
}
.admin-table .type-btn {
  height: 3.5vh;
  line-height: 0;
  font-size: 1.5vh;
}
.admin-table .typeaccept-btn,
.admin-table .typereject-btn {
  height: 3.5vh !important;
  line-height: 0;
  font-size: 1.5vh;
}

.admin-table td {
  font-size: 1.5vh;
  line-height: 1.8vh;
  font-family: "Roboto";
  padding: 1vh !important;
  vertical-align: middle;
}

.heading-row th:first-child {
  border-top-left-radius: 10px !important;
  max-width: 5vw !important;
  min-width: 5vw !important;
}
.heading-row th:last-child {
  border-top-right-radius: 10px !important;
  max-width: 13vw !important;
  min-width: 13vw !important;
}

.data-row {
  background: white;
  text-align: center;
}

.type-btn {
  width: 75px;
  font-size: 14px !important ;
}

.btn-data {
  display: flex;
  justify-content: space-around;
}
.heading-row > th {
  color: #776d6d !important;
  border-radius: unset !important;
  /*     
    border-radius: 10px 0px 0px 0px;
    width:49px;
    height:40px; */
}

.admin-table-bordered td,
.admin-table-bordered th {
  border: 1px solid #f2f2f2 !important;
  /* border-radius: 10px 0px 0px 0px !important; */
}
.admin-table {
  padding: 3vh !important;
  color: grey !important;
  /* border-radius: 20px !important; */
}

.nav-item {
  cursor: pointer;
  margin-left: 4.8rem;
}
li.Wovennavtab.nav-item {
  cursor: pointer;
  margin-left: 3rem !important;
}
.nav-tabs .nav-link.active {
  border-bottom: 2px solid black !important;
  background: none !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.5);
}
/* .nav-tabs .nav-link{
    border: none !important;
} */
.nav-tabs {
  border-bottom: none !important;
  background: #e0e0e0;
}
.nav-item > a {
  color: grey;
}
ul.nav.nav-tabs {
  margin-left: -0.3rem !important;
  position: fixed;
  z-index: 1;
  width: 100%;
}
.col-sm-10 {
  padding: 0 !important;
}

.col-sm-2 {
  padding-right: 0 !important;
}

th.newReqlink {
  background: #e0e0e0;
  border: 1px solid #f2f2f2;
  border-radius: 10px 0px 0px 0px;
  width: 114px;
  height: 40px;
}
.badge-number {
  background-color: #eb5757 !important;
  /* border-radius: 49px !important; */
}

/* th.newReqSdate{
    background: #E0E0E0;
border: 1px solid #F2F2F2;
border-radius: 10px 0px 0px 0px;
width:114px;
height:40px;
} */

/* th.newReqcName{
    background: #E0E0E0;
border-right: 1px solid #F2F2F2;
border-radius: 10px 0px 0px 0px;
width:114px;
height:40px;

}
th.newRequname{
    background: #E0E0E0;
border: 1px solid #F2F2F2;
border-radius: 10px 0px 0px 0px;
width:114px;
height:40px;
}
 th.newReqlink{
    background: #E0E0E0;
border: 1px solid #F2F2F2;
border-radius: 10px 0px 0px 0px;
width:114px;
height:40px;
} 
th.newReqType{
    background: #E0E0E0;
border: 1px solid #F2F2F2;
border-radius: 10px 0px 0px 0px;
width:114px;
height:40px;
}

th.newReqAction{
    background: #E0E0E0;
border: 1px solid #F2F2F2;
border-radius: 10px 0px 0px 0px;
width:187px;
height:40px;

} */
button.typereject-btn.btn.btn-danger {
  background: #eb5757;
  border-radius: 4px;
  width: 75px;
  height: 32px;
  color: #ffffff;
  text-align: center;
  padding: 0px !important;
}
button.typeaccept-btn.btn.btn-primary {
  border-radius: 4px;
  width: 75px;
  height: 32px;
  color: #ffffff;
  padding: 0px !important;
  text-align: center;
  background: #2f80ed;
}

a.View-Linkreq {
  text-decoration: underline !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white !important;
  opacity: 1;
}
.admin-tab {
  position: fixed;
  margin-top: 3vh !important;
}

.admin-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.company-name-fixed {
  width: 14.1vw;
}
.type-fixed {
  width: 14vw;
}
.alert-icon {
  width: 1.6vh;
  height: 1.6vh;
  margin-left: 2px;
}
.font-15{
  font-size: 1.65vh;
}
.admin-button {
  display: flex;
}
.pending {
  margin-bottom: 0 !important;
}

.pending-button {
  width: 198px !important;
}

/* .modal-open .modal#companypreview{
    background: dimgray!important;
} */

#companypreview {
  max-width: 86.12vw;
}
#companypreview .modal-content {
  border-radius: 10px 10px 0px 0px;
}
.tabsimage {
  padding: 2.5vh 0 0 4.66vw !important;
}
.PreviewCompany .tabsimage img {
  width: 6.95vw;
  height: 6.95vw;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.1));
  border-radius: 100px;
  margin: 0;
}
.PreviewCompany .tabsaddress{
  margin-left: 2.16vw;
  margin-top: 0.9vh;
}
.PreviewCompany .tabsaddress p {
  font-weight: 500;
  font-size: 2.4vh;
  line-height: 2.9vh;
  letter-spacing: 0.01em;
  color: #333333;
}
.newUserModaladmin.modal{
  width: 88%;
  left:13.5vw;
}