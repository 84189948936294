.sidenav {
  max-width: 13.89% !important;
  flex: 0 0 13.89% !important;
  height: 100vh;
}
.rightcontent {
  max-width: calc(100% - 13.89%) !important;
  flex: 0 0 86.11% !important;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
ul.greybox {
  margin-top: 5vh !important;
  width: 86.11vw !important;
  height: 3.2vh;
}
.greybox .nav-item {
  margin-left: 4.03vw;
}
.greybox .nav-item .nav-link {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.5);
  padding: 0.7vh 2.89vw 0.4vh;
  border: none;
}
.greybox .nav-item .nav-link:hover,
.greybox .nav-item .active {
  color: #000000 !important;
  border-bottom: 0.2vh solid #000000 !important;
}
#scrollableDiv{
  height: 600px;
}
.RFQ-Row{
  /* margin-top:20px; */
}