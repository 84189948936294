.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  padding-bottom: 40px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #2f80ed ;
  background-color: #0062cc;
  border-color: #005cbf;
}
.forget-btn.signup-not-account.btn.btn-primary:active{
  color:#2f80ed !important;
}
.login-btn {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-size: 1.8vh !important;
  line-height: 2.4vh !important;
  border-radius: 20px !important;
  background: #2f80ed;
  width: 100%;
  height: 5.5vh;
  margin-top: 1.6vh;
  padding: 0 !important;
  margin-left: 0;
}
.link-forgetps {
  float: right;
  margin-top: 3.2vh;
  text-decoration: underline;
  font-family: "Roboto", sans-serif;
  font-size: 1.6vh;
  line-height: 1.9vh;
  color: #2f80ed;
}
.headingLogin {
  text-align: center;
  font-size: 2.4vh;
  line-height: 2.2vh;
  letter-spacing: -0.408px;
  color: #333333;
}
.Loginform {
  margin-top: 5vh;
}

button.forget-btn.btn.btn-primary {
  margin-top: 3.2vh;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500;
  font-size: 1.6vh;
  line-height: 2.4vh;
  color: #2f80ed;
  border: 1px solid #2f80ed;
  border-radius: 20px !important;
  height: 4vh;
  padding: 0;
}
.forget-btn {
  margin-right: 0;
  width: 100%;
  border-radius: 27px !important;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 20px;
  height: 42px;
  background: white !important;
  color: #2f80ed;
}

.verify {
  position: absolute;
  top: 50%;
  left: 43%;
  display: flex;
}

.verified {
  width: 35%;
  position: absolute;
  top: 34%;

  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .login-form {
    padding: 0px 15px;
  }
  .Loginform {
    margin-top: 2em;
  }
  .verified {
    width: 100%;
    padding: 0 30px;
  }
}
