.root-admin {
  display: flex !important;
  /* margin-top: 4rem; */
  flex-direction: row !important;
}

.root-element {
  border: 0.5px solid #828282;
  border-radius: 100px;
  margin: 1rem;
  width: 100%;
  height: 5rem;
}
.image-profile {
  width: 10%;
}
.image-elemnt {
  display: flex !important;
  flex-direction: row !important;
}
.assigned {
  width: 60%;
  background-color: white;
  margin-top: 5.5rem;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.root {
  border: 0.5px solid #828282;
  border-radius: 100px;
  width: 250px;
}

/* .rootUnassigned{
  border: 0.5px solid #828282;
  border-radius: 100px;
 width:250px;
 margin: 2rem 0 2rem 0;
} */
.unassigned {
  width: 20%;
  margin-top: 7rem;
}
.AddUser-button {
  margin-left: 1.4rem;
  line-height: 19px;
  font-size: 18px;
  /* line-height: 21px; */
  color: #2f80ed;
}
/* .modal-content{
  background-color: transparent !important;
} */
img.profile-chart {
  width: 57px !important;
  height: 56px !important;
}
/* .container-chart{
    display: flex !important;
    padding: 20px;
    margin-left: 20px;
  } */

/****MOdal User CSS****/
/***form**/
div.AddUser-FormGroup.form-group {
  display: flex;
}

label.label-addUser {
  display: flex;
}
  /****MOdal User CSS****/
  /***form**/
  div.AddUser-FormGroup.form-group{
display: flex;
  }

  label.label-addUser{
    display: flex;
  }



  div.ModalUser-body.modal-body{
    width: 450px;
    background: #F2F2F2;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    z-index: 1111111111111111;
  }
div.AddUserContent{
  background-color: #F2F2F2;
}
  input#exampleUserName.User-Input.form-control{
    width: 334px !important;
height: 2rem !important;
background: #FFFFFF;
border: 1px solid #828282;
margin-left: 1.5rem;
box-sizing: border-box;
border-radius: 4px;

  }

  input#exampleUserMobile.User-Input.form-control{
    width: 334px !important;
height: 2rem !important;
background: #FFFFFF;
margin-left: 1.5rem;
border: 1px solid #828282;
box-sizing: border-box;
border-radius: 4px;

  }
  input#exampleUserEmail.User-Input.form-control{
    width: 334px !important;
height: 32px !important;
background: #FFFFFF;
border: 1px solid #828282;
box-sizing: border-box;
margin-left: 1.5rem;
border-radius: 4px;
  }

div.ModalUser-body.modal-body {
  width: 450px;
  background: #f2f2f2;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
div.AddUserContent {
  background-color: #f2f2f2;
}
input#exampleUserName.User-Input.form-control {
  width: 334px !important;
  height: 2rem !important;
  background: #ffffff;
  border: 1px solid #828282;
  margin-left: 1.5rem;
  box-sizing: border-box;
  border-radius: 4px;
}

input#exampleUserMobile.User-Input.form-control {
  width: 334px !important;
  height: 2rem !important;
  background: #ffffff;
  margin-left: 1.5rem;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 4px;
}
input#exampleUserEmail.User-Input.form-control {
  width: 334px !important;
  height: 32px !important;
  background: #ffffff;
  border: 1px solid #828282;
  box-sizing: border-box;
  margin-left: 1.5rem;
  border-radius: 4px;
}
input#exampleUserPassword.User-Input.form-control {
  width: 334px !important;
  height: 32px !important;
  background: #ffffff;
  border: 1px solid #828282;
  margin-left: 1.5rem;
  box-sizing: border-box;
  border-radius: 4px;
}

select#exampleSelect.User-Select.form-control {
  width: 46% !important;
  background: none;
  border: none !important;
  margin-left: 2.5rem;
  box-sizing: border-box;
}
select#exampleSelect.User-Select.form-control {
  background-image: linear-gradient(45deg, transparent 50%, blue 50%),
    linear-gradient(135deg, blue 50%, transparent 50%) !important;
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em !important;
  background-repeat: no-repeat !important;
  box-sizing: border-box !important;
  height: 48px;
  margin-left: 1rem !important;
  box-shadow: none;
}

label.AddUser-label.col-sm-2.col-form-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #828282;
}
label.AddUser-label.form-check-label {
  margin: 0.47rem 2rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #828282;
}
/**for tabs**/
ul.Userteamtabs.nav.nav-tabs {
  background: none !important;
  border-bottom: 0.5px solid#828282 !important;
  margin: 3rem 0rem 0 3rem !important;
}

li.UserteamItem.nav-item a.active.nav-link {
  border-bottom: 2px solid #2f80ed !important;
  color: #2f80ed !important;
  padding-top: 10px;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: #828282;
}
/* li.Userteam1Item.nav-item a.active.nav-link{
    border-bottom: 2px solid #2F80ED !important;
    color: #2F80ED !important;
    padding-top: 10px;
     width:8rem; */
/* margin-top: 3rem;
  } */

li.UserteamItem.nav-item {
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: #828282;
  margin-right: 4.7rem;
  margin-left: 0rem !important;
}

/***for plus Icon**/
.add-user {
  height: 18px;
  border-radius: 18px;
  color: black;
  cursor: pointer;
}
