
    
  

  
  
  
/* RESET STYLES & HELPER CLASSES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  --level-1: #8dccad;
  --level-2: #f5cc7f;
  --level-3: #7b9fe0;
  --level-4: #f27c8d;
  --black: black;
}

.containerhie{
  display: flex;
  margin-top: 1.5rem;
}
.team2hierachy{
  margin-left: -4rem;
}

.rectangle {
  position: relative;
  /* padding: 20px; */
  font-size: 18px;
  
}

h3.level-3.rectangle{
  background: #F2F2F2;
    border: 0.5px solid #828282;
    border-radius: 100px;
    position: relative;
    width:245px;
    height: 64px;
    margin-top: 0rem;
}
.level3-text{
  position: absolute;
  margin-top: 0.5rem;
  margin-left: -1rem;
}
.teamName {
  margin-left: 5.2rem;
    margin-bottom: -1.8rem;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #828282;
}
.level3-hr{

}
.teamDesign{
  font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #4F4F4F;
    margin-left: 5.2rem;
    margin-top: -0.8rem;
}
/***for hr border***/
hr.name-border{
  border: 0.5px solid #333333;
  margin-top: 2rem;
  margin-right: -1.3rem;
  margin-left: 5rem;
  border-top: 0px !important;
}
hr.name-border1{
  border: 0.5px solid #333333;
  margin-top: 2rem;
  margin-right: 0.5rem;
  margin-left: 5rem;
  border-top: 0px !important;
}
hr.name-border2{
  border: 0.5px solid #333333;
  margin-top: 2rem;
  border-top: 0px !important;
  margin-right: -0.5rem;
  margin-left: 5rem;
}
/***edit icon for tabs***/
.Edit-Team-Icon-tab{
  height: 17px;
  position: absolute;
  width: 17px;
  left:27%;
  top:5%;
}
.Edit-Team-Icon-tab2{
  height: 17px;
  position: absolute;
  width: 17px;
  right:39%;
  top:5%;
}
.Edit-Team-Icon-tab3{
  height: 17px;
  position: absolute;
  width: 17px;
  right:8%;
  top:5%;
}
/***edit icons for teams**/
.Edit-Team-Icon{
  height: 17px;
    position: absolute;
    width: 17px;
    right:-14%;
    top: 2%;
   
}
.Edit-Team-Icon1{
  height: 17px;
  position: absolute;
  width: 17px;
  right: 11%;
  top: 2%;
}
.Edit-Team-Iconun{
  height: 17px;
  position: absolute;
  width: 17px;
  right: 0%;
  top: 2%;
}
/***for cross icons***/
.CrossIcon{
  height: 18px;
    position: absolute;
    width: 18px;
    right: -19%;
    top: 24%;
    border: 1px solid #DADADA;
    /* background: #DADADA; */
    border-radius: 50%;
}
.CrossIcon1{
  height: 18px;
  position: absolute;
  width: 18px;
  right: -11%;
  top: 24%;
  border: 1px solid #DADADA;
  border-radius: 50%;
}
.CrossIcon2{
  height: 18px;
  position: absolute;
  width: 18px;
  right: -4%;
  top: 24%;
  border: 1px solid #DADADA;
  border-radius: 50%;
}
.CrossIconUn{
  height: 18px;
  position: absolute;
  width: 18px;
  right: -10.8%;
  top: 24%;
  border: 1px solid #DADADA;
  border-radius: 50%;
}
.image-hr{
  display: flex;
}
.level3-image{
  position: absolute;
  top: 4.5%;
    left: 1.5%;
}
.imageteam{
  width:57px;
  height: 56px;
  border-radius: 50%;
}
h4.level-4.rectangle{
  background: #F2F2F2;
    border: 0.5px solid #828282;
    border-radius: 100px;
    position: relative;
    width:250px;
    height: 64px;
}
h4.level-5.rectangle{
  background: #F2F2F2;
    border: 0.5px solid #828282;
    border-radius: 100px;
    position: relative;
    width:250px;
    height: 64px;
}

/* LEVEL-3 STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-3-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
 list-style: none;
  margin: 0 auto;
}





/* LEVEL-4 STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-4-wrapper {
  position: relative;
  width: 80%;
  margin-top: 2rem;
  margin-left: 4rem;
}

.level-4-wrapper::before {
  content: "";
  position: absolute;
  top: -33px;
  left: -20px;
  width: 2px;
  height: calc(70% + 20px);
  background: var(--black);
}

.level-4-wrapper li + li {
  margin-top: 20px;
}

.level-4 {
  font-weight: normal;
  background: var(--level-4);
}

.level-4::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-100%, -50%);
  width: 60px;
  height: 2px;
  background: var(--black);
}
ol{
  list-style: none;
}
li{
  list-style: none;
}
/*level5 ___________________________________________*/
.level-5-wrapper {
  position: relative;
  width: 80%;
  margin-top: 2rem;
  margin-left: 4rem;
}

.level-5-wrapper::before {
  content: "";
  position: absolute;
  top: -5rem;
  left: -20px;
  width: 2px;
  height: calc(70% + 68px);
  background: var(--black);
}

.level-5-wrapper li + li {
  margin-top: 20px;
}

.level-5 {
  font-weight: normal;
  background: var(--level-4);
}

.level-5::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-100%, -50%);
  width: 60px;
  height: 2px;
  background: var(--black);
}


/* MQ STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (max-width: 700px) {
  .rectangle {
    padding: 20px 10px;
  }

  .level-1,
  .level-2 {
    width: 100%;
  }

  .level-1 {
    margin-bottom: 20px;
  }

  .level-1::before,
  .level-2-wrapper > li::before {
    display: none;
  }
  
  .level-2-wrapper,
  .level-2-wrapper::after,
  .level-2::after {
    display: block;
  }

  .level-2-wrapper {
    width: 90%;
    margin-left: 10%;
  }

  .level-2-wrapper::before {
    left: -20px;
    width: 2px;
    height: calc(100% + 40px);
  }

  .level-2-wrapper > li:not(:first-child) {
    margin-top: 50px;
  }
}


