.Profile-Admin {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent !important;
  border-radius: 0.25rem;
}
div.Personal-Info-card.list-group {
  /* width: 368px; */
  /* height: 108px; */
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #828282;
  /* margin-bottom: 2rem; */
}
div.persoanlinfo-li.list-group-item {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #828282;
  padding: 0;
  text-align: left;
  margin:1.1vh 1.3vh;
}
div.persoanlinfo-li.list-group-item span {
  font-weight: 500;
  color: #4f4f4f;
  margin-left: 0.56vw;
}
.PersonalinfoHeading {
  font-weight: 500;
  font-size: 1.4vh;
  line-height: 1.7vh;
  letter-spacing: 0.01em;
  color: #828282;
  margin-bottom: 0.8vh;
}
.me-2 {
  margin-right: 0.5rem !important;
}

.mb-0 {
  width: 24;
  height: 24;
  fill: none;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}
p.Personal-Info-card.card-text {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) ;
  border-radius: 4px;
  padding: 1.6vh 1.12vw;
  margin-bottom: 1.6vh;
}

p.Contact-Info-card.card-text {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);;
  border-radius: 4px;
  padding: 1.6vh 1.12vw;
  margin-bottom: 1.6vh;
}
div.Card-ProfileScreen.card-body {
  padding: 0 1.12vw;
}
.ProfilePic-profile {
  width: 20vh;
  margin:auto;
  /* text-align: center; */
  
}
.labelpass{
  font-family: Lato;
  font-size: 1.6vh !important;
  font-style: normal;
  font-weight: 500;
  min-width: 15vh;
  line-height: 1.8vh;
  letter-spacing: .01em;
}
.psw-input input{
border-color: #828282 !important;
}
.ProfilePic-profile > div {
  display: inline-block;
  margin: 0 auto;
  width: inherit;
}
.ProfilePic-profile img {
  width: 15vh;
  margin: auto;
}
.top-header-profile{
  font-family: Lato;
  font-size: 2.2vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.6vh;
  letter-spacing: .01em;
  text-align: center;
  color: #828282;
  margin-top: 4vh;
  margin-bottom: 2.3vh;
}
.upload-profile-image {
 visibility: hidden;
 height: 22px !important;
  /* display: none; */
}
.ProfileOverlay-body.modal-body{
  width: 40vh;
}
.edirPersonalinfo {
  height: 23px;
  width: 23px;
  float: right;
}

div.Buis-button {
  margin: 0rem 0rem 1rem 0rem;
}
button.ScreenNext-btn.btn.btn-primary {
  background-color: #ffffff;
  color: #c8ced4;
  padding: 0px !important;
}
.Changepas {
  text-decoration: underline;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #2f80ed;
  display: flex;
}

.Changepassp {
  margin: 1rem;
}

/***Modal***/
div.modal-dialog.ProfileOverlay-Modal {
  margin: 0 0 0 2vh !important;

  
}

div.ProfileOverlay-Modal.modal.show {
  background-color: transparent !important;
  width: 40vh !important;
  display: flex !important;
  top: 40px;
  margin: 0 0 0 10px !important;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);

height: max-content;
}
.ProfileOverlay-Modal .modal-dialog {
  margin-top: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 0 !important;

}
.ProfileOverlay-Modal.show{
  padding-top:0 !important;
  margin-bottom: 0 !important;
}
.ProfileOverlay-Modal .modal-dialog .modal-body {
  padding: 0;
}
.ProfileOverlay-Modal .modal-dialog .modal-body .Buis-button {
  margin: 1.7vh 10px 3.2vh 0;
}
.ProfileOverlay-Modal .modal-dialog .modal-body .btn-primary,
.change-password-footer .btn-primary {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
  font-size: 1.4vh !important;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  background: #2f80ed;
  border-radius: 4px;
  width: 7.5vw;
  height: 3.2vh;
  margin:0 0 0 1.19vw;
  padding: 0 !important;
}
.btnwrapper{
  display: flex;
  justify-content: center;
}
div.modal.Profileverlay-Modal.fade.show {
  margin: 0rem !important;
  padding: 0rem !important;
  background-color: #fff !important;
}

div.ProfileOverlay-User.modal-header {
  /* margin-top: -4rem; */
  background-color: #fff !important;
  padding: 0;
}
div.ProfileScreen-Card.card {
  border: none !important;
}
.change-password-header {
  margin: auto;
  padding: 4vh 0 2.5vh !important;
}
.change-password-header h5 {
  font-size: 2.2vh;
  line-height: 2.6vh;
  letter-spacing: 0.01em;
  color: #828282;
}
.change-password-text {
  font-size: 1.4vh;
  line-height: 1.7vh;
  letter-spacing: 0.01em;
  color: #2f80ed;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 1.12vw;
}
.change-password-form {
  background: #ffffff;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  border-radius: 4px;
  padding: 1.6vh 1.12vw 0 !important;
  margin: 0 1.12vw;
}
.change-password-form label {
    color: #828282 !important;
    margin-bottom: 0 !important;
    margin-top: 0.7vh;
}
.change-password-form .form-control {
    height: 3.2vh !important;
}
.change-password-footer {
  display: flex;
  align-items: center;
  padding:2vh 0 !important;
}
.form-text {
  margin-left: 1rem;
  color: red;
}
