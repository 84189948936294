.text-20{
    font-family: 'Lato', sans-serif;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #333333;
}
.text-16,
.text-14{
    font-family: 'Lato', sans-serif;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: rgba(51, 51, 51, 1);
    font-weight: 500;

}
.text-18,
.text-16 span,
.text-14 span{
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    margin-left: 8px;
}
.text-14{
    font-size: 14px;
    line-height: 17px;
}
.border-line{
    border-bottom: 0.5px solid #828282;
}
.margin-l40{
    margin-left: 40px;
}
.margin-l16{
    margin-left: 16px;
}

/* Maharani Box */
.maharani-heading{
    margin: 34px 0px 5px;
}
.maharani-box{
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    padding: 36px 16px 16px;
}
.warp{
    margin: 18px 16px 10px;
}
.count span{
    margin-left: 47px;
}
.comments span{
    margin-left: 18px;
}
.fabric-customize .text-underline{
    text-decoration: underline;
}
.fabric-customize .text-16{
    font-weight: 500;
    color: #333333;
}
.fabric-customize .text-14,
.fabric-customize .text-16{
    margin-bottom: 8px;
}
.fabric-customize .numbering{
    margin-top: 25px;
    margin-right: 8px;
}
.fabric-customize .numbering .text-16{
    color: #828282;
    font-weight: 400;
}
.fabric-customize .colour-code{
    margin-left: 43px;
    margin-right: 45px;
}
.fabric-customize .quantity{
    text-align: end;
}
.delivery-to{
    font-weight: 400 !important;
    margin-left: 0 !important;
    color: #828282 !important;
}

/* Seller Response */
.seller-response{
    background: #FFFFFF;
    box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 32px 16px 42px;
    width: 500px;
    margin-left: -16px;
}
.seller-response .text-18{
    /* font-size: 18px; */
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #4F4F4F;
    margin-left: 0;
}
.seller-response .text-16{
    color: #4F4F4F;
}
.seller-response .form-control{
    font-family: 'Lato', sans-serif;
    width: 100px;
    height: 48px;
    padding: 8px;
    line-height: 19px;
    letter-spacing: 0.01em;
    /* color: #f17979 !important; */
    border: 0.5px solid #000000;
}
.seller-response #Comments{
    width: 325px;
    height: 80px;
    resize: none;
}
.seller-response .form-control:focus{
    box-shadow: none;
    border-color: #000;
}
.seller-response .form-group ::placeholder{
    color: #C4C4C4;
}
.seller-response .inr{
    margin-left: 8px;
}
.seller-response .delivery-date{
    margin: 0 8px;
}
.seller-response #Payment{
    margin-left: 31px;
}
.response-button{
    height: 32px;
}
.seller-response .button-bottom{
    margin-right: 0;
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
}
.seller-response .button-bottom:focus{
    box-shadow: none;
}

/* Fill Information */
.fill-information{
    box-shadow: none; 
    border: 1px solid #F2994A;
    border-radius: 4px;
    padding: 16px 8px 15px 16px;  
    margin-top: 16px;

    width: 468px;

}
.fill-information #PaymentTerms{
    width: 193px;
    margin-left: 16px;
}
.fill-information #Comments{
    width: 308px;
    height: 48px;
}
/* .fill-information .optional-comments{
    margin-left: 25px;
} */
.fa-check{
    color:green
}
.fa-check-square{
    color: green;
    width: 16px;
    margin-left: 7px;
}
.modal-dialog{
    margin-top: 70px;
}
.maharani-heading{

    margin-left: 31px;
position: absolute;
top: -80px;
}

.modal-body{
    font-size:16px !important;
}

.modal-body1{
    padding: 1rem 1rem 0rem 1rem !important;
}

.text-111{
    font-weight: 500;
}