/****Opem enq page****/

div.OpenEnq1.container{
    
background: #FFFFFF;
box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 4px;
padding: 1rem 2rem 1rem 2rem !important;
margin-top: 2rem;


}

div.OpenEnqrow.row.row-cols-3{
    margin-bottom: 0.75rem;
}
div.OpenEnqrow.row.row-cols-6{
    margin-bottom: 0.75rem;
}
div.OpenEnqrow.row.row-cols-5{
    margin-bottom: 0.75rem;
}

div.OpenEnqView{
    display: flow-root;
    margin: -2.5rem 0.8rem 0rem 0rem;
}
button.Order-samButton.btn.btn-secondary{
    background: #D35C6A;
border-radius: 8px;
height: 1.063rem;
width: 11.25rem;
border-radius: 8px;
border:none !important;
font-size: 0.875rem;
text-align: center;
padding: 0px !important;
font-weight: bold;
margin-left: 0.5rem;
line-height: 16px;
/* identical to box height */

text-align: center;
letter-spacing: 0.02em;

/* white */

color: #FFFFFF;

}
button.OpenView-btn.btn.btn-secondary{
    width: 150px;
height: 32px;
background: #2F80ED;
border-radius: 4px;
padding: 0px !important;
}
/*layout css_____*/
/**for heading of col**/
div.OrderCol.col{
font-style: normal;
font-weight: 500;
font-size: 1rem !important;
line-height: 19px;
letter-spacing: 0.01em;
color: #333333 !important;
}
div.latestCol.col{
    font-style: normal;
font-weight: 500;
font-size: 0.875rem;
line-height: 17px;
letter-spacing: 0.01em;
display: flex;
color: #333333 !important;
}
div.SubCol.col{
    font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
text-align: end !important;
letter-spacing: 0.01em;
color: #4F4F4F;

}

/**for row second heading*/
div.Colfabric.col{
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #828282;
}
div.Colcons.col{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #828282;
    margin-left: 5rem;
    }
div.Colfabric.col span{
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #4F4F4F;
white-space: nowrap !important;
  overflow: hidden !important;
}
div.ColRes.col{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #828282;
  margin-left: -5rem;
}



div.Qtycol.col{
    display: flex;
    flex-direction: row-reverse;
    margin-left: -4rem;
    /**for fobt**/
    font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #828282;
}

div.TarCol.col{
     /**for fobt**/
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
     line-height: 17px;
     letter-spacing: 0.01em;
     color: #828282;
}
div.DelscCol.col{
    /**for fobt**/
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-left: 2.9rem;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #828282;
}
div.WidthCol.col{
    /**for fobt**/
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #828282;
    margin-left: 2rem;
}

div.SeenCol.col{
    margin-left: -2.8rem;
    /**for fobt**/
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #828282;
}

div.NumbCol.col{
margin-left: -7rem;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #4F4F4F;

}
div.NumbCol1.col{
    margin-left: -4.85rem;
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #4F4F4F;
    }

div.DateCol.col{
    margin-left: -0.4rem;
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #4F4F4F;

}
div.DateCol1.col{
    margin-left: 1.6rem;
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #4F4F4F;
white-space: nowrap !important;
  overflow: hidden !important;
}
div.WidNoCol.col{
    margin-left: 0.5rem;
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #4F4F4F;
}
div.WidNoCol1.col{
    margin-left: 2.8rem;
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #4F4F4F;
}
div.ColFabno{
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #4F4F4F;
}
div.QtyNo.col{
    margin-left: -3.5rem;
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #4F4F4F;
}

/***for main div of open enq***/
div.tab-content.OpenEnq-Active{
    margin-left: 1rem;
    margin-right: 2rem;
    margin-top: 7rem;
}

/***for nav tabs**/
li.OpenEnq-tabs.nav-item{
    margin-left: 3rem;
}

ul.top-nav-Open.list-group{
    margin-left: 3.2rem;
    display: flex;
    flex-direction: row !important;
    padding-left: 0;
    margin-right: 0rem;
    margin-bottom: 7px;
    border-radius: .25rem;
    text-align: left !important;
}

span.sapn-to{
    font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
/* identical to box height */
letter-spacing: 0.01em;
color: #828282;
}

/***for view more button**/

div.ViewMore.col{
    display: flex;
    margin-left: -5rem;
    margin-top: 0rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    letter-spacing: 0.01em;
    color: #2F80ED;
}

div.ViewMore.col button{
    background-color: white;
    border: none !important;
    font-weight: 500;
font-size: 14px;
line-height: 17px;
align-items: center;
letter-spacing: 0.01em;
text-decoration-line: underline;
color: #2F80ED;

}
/***for view screen***/
div.BuyerOpenView{
    background: #FFFFFF;
box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 4px;
height: 878px;
width: 1176px;

}
div.Vardhmandiv{
    background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
border-radius: 4px;
height: 130px;
width:90%;
display: flex;
margin: 1rem 3rem 0 3rem;

}
p.Ratingsp{
    white-space: nowrap;
    margin-left: -3.5rem;
}
p.PerRating{
    margin-left: -2rem;
    margin-top: -1rem;
}
.Vardhmanlogo{
    height: 50px;
border: 0.5px solid #BDBDBD;
box-sizing: border-box;
filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.1));

}

div.OpenEnqview{
   width: 18%;
   display: block;
  margin-left: 1rem;
  margin-top: 1.5rem;
}
div.MillsPrice.col {
    margin-left: 1.5rem;
    text-decoration-line: underline;
    }
p.HiddenContent{
    visibility: hidden;
}

div.MillsDel.col{
    white-space: nowrap;
    margin-left: 5rem;
    text-decoration-line: underline;
    }

p.MillsColour{
    white-space: nowrap;
    margin-top: -0.5rem;
}
  p.Pricep{
      margin-top: 0.7rem;
      font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
text-decoration-line: none !important;
color: #4F4F4F;

  } 
  p.price{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-decoration-line: none !important;
    color: #4F4F4F;  
  }
  span.price{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-decoration-line: none !important;
    color: #4F4F4F;  
  }
div.MillsPay.col{
        white-space: nowrap;
        margin-left: 3rem;
           
           
        }
   

        div.OpenEnqview1{
            display: flex !important;   
         margin-left: 4rem;   
         font-weight: normal;
         font-size: 14px;
         line-height: 17px;
         letter-spacing: 0.01em;

         color: #828282;
         margin-top: 1.5rem;
         
         }

         p.millstag{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #4F4F4F;
            white-space: nowrap;
            margin-top: 1rem;
            text-align: center;
         }
p.ButtonMills{
    margin-top: 2rem;
}
         button.BuyerView-button.btn.btn-secondary{
            width: 150px;
        height: 32px;
        background: #2F80ED;
        border-radius: 4px;
        padding: 0px !important;
        }

        button.Buyerback-button.btn.btn-secondary{
            width: 150px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #2F80ED;
        box-sizing: border-box;
        border-radius: 4px;
        color: #2F80ED;
        margin: 8rem 4rem 0rem 0rem;
        padding: 0px !important;
        }

        /*****for view more button scren***/

div.Viewmore.row.row-cols-5{
    margin-left: -1rem;
    font-weight: normal;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
text-decoration-line: underline;
color: #828282;
}



div.ViewmoreCol.col{
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #4F4F4F;
margin-left: 0rem;
margin-top: 0.5rem;
}

div.ViewmoreCol2.col{
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.01em;
color: #4F4F4F;
margin-left: -4rem;
margin-top: 0.5rem;
}
div.ViewmoreCol3.col {
    margin-left: -4rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #4F4F4F;
    margin-top: 0.5rem;
}
div.ViewmoreCol4.col {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #4F4F4F;
    margin-top: 0.5rem;
}
div.colourcol.col{
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #828282;
}

div.colourcol.col span{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #4F4F4F;
  margin-left: 0.5rem;
}
div.colourcol1.col span{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #4F4F4F;
  margin-left: 3.5rem;
}

div.ViewmoreColtag.col {
    margin-left: -4rem;
}
p.Orderresult{
    font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.01em;
color: #333333;

}

img.Viewmorecross{
    float: right;
    margin-top: -2rem;
    width:20px;
    height: 20px;
}

/****for scroll bar******/
.scrollarea .scrollbar-container.vertical .scrollbar{
    background: rgba(130, 130, 130, 0.5);
box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 4px;
margin-left: -5rem !important;
margin-top: 1rem !important;
}
.nodata{
    text-align: center;
}