/***for header***/
/* .modal-header{
    border-bottom: none !important;
    background-color: #f2f2f2 !important;
    display: flex !important;
    justify-content: center !important;
    color: #333333 !important;
font-size: 24px;
font-weight: 500;
line-height: 22px;
letter-spacing: -0.40799999237060547px;
} */
div.modal.show {
  /* display: flex !important;
    justify-content: center !important;
    align-items: center !important; */
  /* background: #f2f2f2 !important; */
  padding-top: 5vh;
  /* background: rgba(255, 255, 255, 0.1) !important; */
  backdrop-filter: blur(1px);
}
/***for modal footer***/
.modal-footer {
  border-top: none !important;
}

/***for bg of modal**/
.modal-open .modal {
  background-color: #f2f2f2 !important;
}

/***heading of modal**/
.modal-header-p {
  color: #000000 !important;
}
/***modal-Content***/
.modal-content {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  border: none !important;
  background: #ffffff;
  box-shadow: 1px 1px 10px rgb(0 0 0 / 4%);
  border-radius: 10px;
  /* box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1); */
}
div.SignUp-Modal {
  text-align: center;
  padding: 1rem;
  background: #f2f2f2;
  color: #333333 !important;
  font-size: 24px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.408px;
}
div.ModalSignupBOdy {
  height: 176px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 18px;
  color: #4f4f4f;
}
/***ffor modal buttom**/

.Modal-button {
  height: 32px !important;
  width: 150px !important;
  left: 819px !important;
  top: 536px !important;
  border-radius: 16px !important;
  text-align: center !important;
}

.SignupModalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid rgba(0,0,0,.2); */
  border-radius: 0.3rem;
  outline: 0;
}

div.modal-dialog.SignupModalContent {
  margin-top: 11rem;
}

button.Modal-back-button.btn.btn-primary {
  background: #2f80ed !important;
  width: 150px !important;
  height: 32px;
  border-radius: 16px !important;
  text-align: center !important;
  padding: 0px !important;
}
div.SignUp-Modal.modal-header {
  border: 0.2px solid #f2f2f2 !important;
  border-top: none !important;
  border-bottom: none !important;
  background-color: #f2f2f2 !important;
  display: flex !important;
  justify-content: center !important;
  color: #333333 !important;
  font-size: 24px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
}

.congratulations{
  background-color: transparent !important;
}