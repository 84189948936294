/**for category***/
#TableCat {
  width: 100%;
  border-radius: 10px 10px 0px 0px !important;
  margin-top: 2rem;
}
#TableCat table td,
#TableCat table th {
  border-top: 0px !important;
}
#TableCat td {
  width: 100px;
  height: 50px;
  padding: 10px;
  background: #f2f2f2 !important;
  /* border-radius: 10px 10px 0px 0px !important; */
  border-right: 3px solid white;
  border-bottom: 3px solid white !important;
}
#TableCat table {
  width: 85%;
  padding: 10px;
  border-collapse: collapse;
  border-radius: 10px 10px 0px 0px !important;
  overflow-y: scroll !important;
}
#TableCat tbody {
  overflow: auto !important;
}

/***for table fields**/
#TableProcat {
  width: 100%;
  border-radius: 10px 10px 0px 0px !important;
  margin-top: 4.6vh;
  display: flex;
  justify-content: space-between;
}

#TableProcat table td,
#TableProcat table th {
  border-top: TableCat0px !important;
}
#TableProcat td {
  padding: 0;
}
#TableProcat table {
  padding: 10px;
  border-collapse: collapse;
  border-radius: 10px 10px 0px 0px !important;
  overflow-y: scroll !important;
}
#TableProcat tbody {
  overflow: auto !important;
}
.break {
  flex-basis: 100%;
  height: 0;
}
th.srno {
  background: #e0e0e0 !important;
  border-radius: 10px 10px 0px 0px !important;
  width: 100px !important;
  border-right: 3px solid white !important;
  height: 40px !important;
}
th.fabricstorage {
  background: #e0e0e0 !important;
  border-radius: 10px 10px 0px 0px !important;
  width: 300px !important;
  border-left: 1px solid white !important;
  height: 40px !important;
}
th.fabricStage {
  background: #e0e0e0 !important;
  border-radius: 10px 10px 0px 0px !important;
  width: 300px !important;
  border-left: 1px solid white !important;
  height: 40px !important;
}
th.range {
  background: #e0e0e0 !important;
  border-radius: 10px 10px 0px 0px !important;
  width: 20% !important;
  border-left: 3px solid white !important;
  height: 40px !important;
}
/***for select option***/

/***for input Fields**/
#Add-Fab-Fields {
  display: flex;
  flex-wrap: wrap;
}

#Add-Fab-Fields label {
  display: block;
  color: #4f4f4f;
  margin-bottom: 0 !important;
  text-align: left;
}

select#storage.form-control {
  border: 0.5px solid #000000 !important;
  border-radius: 4px !important;
  width: 300px !important;
  height: 48px !important;
  background: #ffffff !important;
  border: 0.5px solid #000000 !important;
  box-sizing: border-box;
}
select#storage1.form-control {
  border: 0.5px solid #000000 !important;
  border-radius: 4px !important;
  width: 300px !important;
  margin-right: 1rem !important;
  height: 48px !important;
  background: #ffffff !important;
  border: 0.5px solid #000000 !important;
  box-sizing: border-box;
}

select option {
  width: 10px !important;
  height: 100px !important;
  border-bottom: 1px solid black;
}

Select#storage1.form-control {
  background-image: linear-gradient(45deg, transparent 50%, blue 50%),
    linear-gradient(135deg, blue 50%, transparent 50%) !important;
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em !important;
  background-repeat: no-repeat !important;
  width: 20.84vw !important;
  height: 4.8vh !important;
}
select#fabricStage1.form-control,select#fabricStage2.form-control, select#fabricStage3.form-control  {
  background-image: linear-gradient(45deg, transparent 50%, blue 50%),
    linear-gradient(135deg, blue 50%, transparent 50%) !important;
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em !important;
  background-repeat: no-repeat !important;
  width: 20.84vw !important;
  height: 4.8vh !important;
  border: 0.5px solid #000000 !important;
}

select#fabricStage.form-control {
  border: 0.5px solid #000000 !important;
  border-radius: 4px !important;
  width: 300px !important;
  height: 48px !important;
  background: #ffffff !important;
  border: 0.5px solid #000000 !important;
  box-sizing: border-box;
}

/***Prodt Form**/
form.ProductForm {
  background: #ffffff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100% !important;
  padding: 1.3vh 0 1.9vh 1.53vw;
}
/**headinf of product categories**/
.heading-Product {
  text-align: left !important;
  font-weight: 500;
  font-size: 2.4vh;
  line-height: 2.9vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  margin-top: -2rem;
}
.heading-Product1 {
  font-weight: 500;
  font-size: 2.4vh;
  line-height: 2.9vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
}
.type-edit h6 {
  font-weight: 400;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: 0.01em;
  color: #828282;
}
.type-edit span {
  font-weight: 400;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: 0.01em;
  color: #828282;
}
.heading-Product span {
  font-weight: 500;
  font-size: 2vh;
  line-height: 2.4vh;
  letter-spacing: 0.01em;
  color: #4F4F4F;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.heading-Product1 span {
  text-align: left !important;
  font-weight: 500;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.mb-33{
  margin-bottom: 3.3vh !important;
}
/**for submit button**/
.Product-Submit {
  position: absolute;
  bottom: 2rem;
  right: 4rem;
  width: 150px;
  height: 32px;
  background: #2f80ed;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 4px;
}

.heading-Product1 h6 {
  font-weight: 400;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: 0.01em;
  color: #828282;
  margin-bottom: 1.4vh;
}
.heading-Product1 h6 span {
  font-weight: 600 !important;
  font-size: 1.8vh !important;
  line-height: 2.2vh;
  letter-spacing: 0.01em;
  color: #333333 !important;
  margin-left: 0.7vw;
}
hr {
  margin-top: 1.6vh !important;
  margin-bottom: 1.6vh !important;
  border-top: 0.5px solid #828282 !important;
}

/*****Product catalogur page started****/
#Add-Product-Fields {
  display: inline-flex;
  text-align: left !important;
  margin-left: 1rem !important;
}

#Add-Product-Fields label {
  display: block;
  margin: 15px 0;
  margin-right: 1rem !important;
  color: #4f4f4f;
}

select#warp.form-control {
  background: #ffffff !important;
  border: 0.5px solid #000000 !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  width: 13.89vw !important;
  height: 4.8vh !important;
  margin-top: 0.8vh;
}

select#warp.form-control {
  background-image: linear-gradient(45deg, transparent 50%, blue 50%),
    linear-gradient(135deg, blue 50%, transparent 50%) !important;
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em !important;
  background-repeat: no-repeat !important;
}

select#weft1.form-control {
  background-image: linear-gradient(45deg, transparent 50%, blue 50%),
    linear-gradient(135deg, blue 50%, transparent 50%) !important;
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em !important;
  background-repeat: no-repeat !important;
  border: 0.5px solid #000000 !important;
  box-sizing: border-box !important;
  border-radius: 4px;
  width: 13.89vw !important;
  height: 4.8vh !important;
  margin-left: 1.67vw !important;
  margin-top: 0.8vh;
  margin-right: 2.16vw;
}

select#weft.form-control {
  background: #ffffff !important;
  border: 0.5px solid #000000 !important;
  box-sizing: border-box !important;
  border-radius: 4px;
  width: 200px !important;
  height: 48px;
  margin-left: 1rem !important;
}
input#Range.form-control {
  background: #ffffff !important;
  border: 0.5px solid #000000 !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  width: 6.95vw !important;
  height: 4.8vh !important;
  margin-left: 1.67vw !important;
  margin-top: 0.8vh;
}
input#Range1.form-control {
  background: #ffffff !important;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100px !important;
  margin: 1.4rem 0px 0px 1rem !important;
}

#Add-Product-Fields button {
  background: #2f80ed;
  border-radius: 4px;
  height: 32px;
  width: 150px;
  height: 32px;
  margin: 1.5rem 0px 0px 1rem !important;
  border: none !important;
  color: white;
}

span#text {
  margin-left: 1.67vw !important;
  margin-bottom: 8px;
}
span#textcat {
  font-weight: 500;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
}
.DeleteProduct {
  display: inline-flex;
  height: 23px;
  float: right !important;
  cursor: pointer;
  margin-top: -1.5rem;
}
.DeleteProductcat {
  display: inline-flex;
  height: 15px;
  float: right !important;
  cursor: pointer;
  margin-top: -16px;
}
.DeleteProductcatalogue {
  display: inline-flex;
  height: 15px;
  float: right !important;
  cursor: pointer;
  margin-top: 0px;
}
.DeleteProductcat1 {
  display: inline-flex;
  height: 15px;
  float: right !important;
  cursor: pointer;
}
button.ant-btn.btn.btn-primary.product-submit {
  background: #2f80ed;
  border-radius: 4px;
  height: 32px;
  width: 150px;
  height: 32px;
  margin: 1.5rem 0px 0px 1rem !important;
  border: none !important;
  color: white;
}
/***for alert in product screen**/
.alert-product {
  display: inline-flex;
  height: 16px;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
}
td#Deletetd {
  background: transparent !important;
  width: 4vw !important;
}
/* select {
    background-color: white;
    border: thin solid blue;
    border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

/* margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}  */

/* arrows */

.product-tabcontent {
  display: flex;
  justify-content: center;
  /* width: 1096px;
    overflow-x: scroll; */
  /* margin-top: 2.3rem; */
}

.Product-catalogue {
  margin: 11.6vh auto 4.1vh;
  padding: 1.3vh 1.12vw;
  width: 69.45vw;
  background: #ffffff;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 90vh;
}
/* .nav-tabs .nav-link.active{
  border-bottom: 1rem solid black !important;
} */

.nav-items {
  margin-left: 0;
}
.nav-tabs {
  width: 1096px;
  overflow-x: scroll;
  margin: 1.8rem 0rem 0rem -0.7rem;
}

.nav-tabs::-webkit-scrollbar {
  /* background: transparent; */
  display: none;
}
input {
  height: 48px !important;
}

.navigation--active {
  color: rgb(0, 0, 0) !important;
  border-bottom: 3px solid black !important;
}
.Edit-product {
  width: 1.05vw;
  height: 1.05vw;
}
.type-edit {
  display: flex;
 /* justify-content: space-between; */
}

/****for submit button in product cat***/
.ProductCatS-button {
  display: flex;
  flex-direction: row-reverse;
}
.ProductCatS-button .btn-primary {
}

/***for nav tabs for product cata***/
/* ul.productcata-navtabs.nav.nav-tabs {
  margin-left: -2.45rem !important;
} */

li.nav-items-productcata.nav-item {
  margin-left: 2.5rem !important;
  width: max-content;
}

/***for table of product catalogue result**/
table.TableProductCategory.table {
  width: 96% !important;
}

/***for Product catalogue div **/
div.ProductCategKnit.col-sm-12 {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 1000px;
  margin-top: 6rem !important;
  margin: 20px auto;
  text-align: center;
  background-color: white !important;
}

input#Range.form-control::after {
  position: absolute;
  content: "-";
}
.Product-add {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 90%;
  margin-top: 4rem !important;
  margin: 20px auto;
  text-align: center;
  background-color: white !important;
  /* height: 500px; */
  margin-left: 2rem;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
}
.nav-tabs--active .nav-link {
  border-bottom: 2px solid black !important;
}
.heading-backend {
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(51, 51, 51, 1) !important;
  text-transform: capitalize;
}

.twoinputs {
  display: flex;
}
.dash {
  margin-left: 0.7vw;
  margin-right: -0.98vw;
  margin-top: 1.5vh;
}
.warp-i {
  margin-left: 0rem;
  margin-bottom: 0.8vh;
}

.profile-table th.srno {
  background: #e0e0e0 !important;
  border-radius: 10px 10px 0px 0px !important;
  width: 6.95vw !important;
  height: 4vh !important;
  border-right: 2px solid #fff !important;
  border-bottom: 2px solid #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #696969;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
.profile-table th.fabricstorage,
.profile-table th.fabricStage {
  background: #e0e0e0 !important;
  border-radius: 10px 10px 0px 0px !important;
  width: 20.84vw !important;
  height: 4vh !important;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #696969;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  border-left: none !important;
}
.profile-table th.range {
  background: #e0e0e0 !important;
  border-radius: 10px 10px 0px 0px !important;
  width: 13.89vw !important;
  height: 4vh !important;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #696969;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  border-left: none !important;
}
.profile-table tr {
  display: flex;
}
.profile-table tr td:first-child {
  background: #f2f2f2 !important;
  width: 6.95vw !important;
  height: 5vh !important;
  border-right: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #4f4f4f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
.profile-table tr td:nth-child(2),
.profile-table tr td:nth-child(3),
.profile-table tr td:nth-child(4)
 {
  background: #f2f2f2 !important;
  width: 20.84vw !important;
  height: 5vh !important;
  border-right: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #4f4f4f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  position: relative;
}
.profile-table tr td:nth-child(5) {
  background: #f2f2f2 !important;
  width: 5.89vw !important;
  height: 5vh !important;
  border-right: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #4f4f4f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  position: relative;
}
.empty-div{
  width: 35vw;
}
#Add-Fab-Fields button,
.ProductCatS-button button {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700;
  font-size: 1.4vh;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  background: #2f80ed;
  color: #ffffff;
  border-radius: 4px;
  width: 10.42vw;
  height: 3.2vh;
  border: none;
  padding: 0;
  margin: 3.5vh 0 0 3.34vw;
}
.del-new-container{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.5vw;
  padding-right: 1rem;
}