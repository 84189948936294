.Modal-Newreq {
  background-color: #ffffff !important;
}
/* .Header{
    display: none !important;
} */

.modal-open .modal {
  background-color: rgba(255, 255, 255, 0.8) !important;
  /* backdrop-filter: blur(6px); */
}

.modal-header {
  border-bottom: none !important;
}
.p0 {
  padding: 0 !important;
}
.pr2 {
  padding-right: 2vh !important;
}

/* .modal .modal-dialog {
  background: #ffffff !important;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
} */
