/****Main class and card from signup***/

.text-danger {
  color: #dc3545!important;
  font-size: 1.6vh;
  padding-top: 1.6vh;
  margin-bottom: 0;
}

.Frame {
  margin: 5vh 0 5.2vh;
}

.Card1 {
  width: auto;
  /* padding: 20px; */
  border-radius: 10px;
}

.Cards {
  width: 48.48vw;
  padding: 3.6vh 1.12vw 3.2vh;
}
/****for heading***/
.Summary-heading {
  font-weight: 600;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  margin-bottom: 2.1vh;
}
label {
  font-size: 1.6vh !important;
  line-height: 1.9vh !important;
  letter-spacing: 0.01em !important;
  color: #4f4f4f !important;
  margin-bottom: 0.8vh !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.form-control {
  font-family: "Lato", sans-serif;
  font-size: 1.8vh !important;
  line-height: 2.2vh !important;
  letter-spacing: 0.01em !important;
  color: #333333 !important;
  border: 0.5px solid #000000 !important;
  height: 4.8vh !important;
  padding: 0 0.56vw !important;
}
textarea {
  font-family: "Lato", sans-serif !important;
  font-size: 1.8vh !important;
  line-height: 2.2vh !important;
  letter-spacing: 0.01em !important;
  color: #333333 !important;
  border: 0.5px solid #000000 !important;
  padding: 0.8vh 0.56vw !important;
  height: 13.9vh;
  resize: none !important;
}
textarea::placeholder {
  font-size: 1.2vh !important;
  line-height: 1.4vh !important;
  color: #c4c4c4 !important;
  bottom: 0.2vh !important;
  right: 0.28vw !important;
  position: absolute !important;
}
.fontsize-16 {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
}
.mb-16 {
  margin-bottom: 1.6vh !important;
}
.RFS-Label {
  font-size: 1.6vh;
  margin-top: 5px !important;
  text-align: left !important;
}

.RFS-Label-d11,
.RFS-Label-d15 {
  text-align: right !important;
}
.RFS-Label-d3 {
  margin-left: -30px;
}
.RFS-Label-d15 {
  margin-right: -30px;
}
input#file-upload-button {
  border-color: white !important;
  background-color: white !important;
}
/***image uploader***/
.image-uploader {
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}
.mb-21 {
  margin-bottom: 2.1vh !important;
}

label.editPicCompany {
  margin-left: 10px;
}
.image-border1 > img {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  border: 0px solid white;
  object-fit: contain;
  /* display: none; */
}
.summary img:onclick {
  display: block;
}
.certificate-uploader {
  font-size: 1.6vh;
  line-height: 1.9vh;
  /* identical to box height */
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #2f80ed;
}
/***for Buisness description screen***/
/***for textarea***/

#exampleText {
  /* height: auto !important; */
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 4px;
  overflow: hidden;
}
textarea#exampleText.form-control {
  font-size: 1.8vh !important;
  line-height: 2.2vh !important;
  letter-spacing: 0.01em !important;
  color: #333333 !important;
  border: 0.5px solid #000000 !important;
  padding: 0.8vh 0.56vw !important;
  height: 13.9vh !important;
}
textarea#exampleText.form-control::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 1.2vh;
  line-height: 1.4vh;
  letter-spacing: 0.01em;
  text-align: right;
  color: #c4c4c4;
  font-size: --placeholderFontSize;
  line-height: calc(
    (var(--textareaHeight) * 1.87) - var(--placeholderFontSize)
  );
}
/* :root {
  --textareaHeight: 139px;
  --placeholderFontSize: 12px;
} */
textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  position: absolute;
  bottom: 10px;
  right: 16px;
}
textarea#companyPurpose {
  width: 100% !important;
  /* height: 139px !important; */
  background-clip: padding-box;
  border: 1px solid #ced4da;
  overflow: hidden !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 10px;
}
textarea#companyPurpose::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 1.2vh;
  line-height: 1.4vh;
  text-align: right;
  letter-spacing: 0.01em;
  color: #c4c4c4;
  text-align: right;
  font-size: --placeholderFontSize;
  line-height: calc(
    (var(--textareaHeight) * 1.87) - var(--placeholderFontSize)
  );
}
/****for add button***/
.Btn-text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  margin-left: 0.56vw;
  color: #2f80ed;
}
.Btn-add-photos {
  font-weight: 600;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
}
.rating{
  font-size: 1.4vh;
  color: #828282;
}

button.BusinessNext-btn.btn.btn-primary {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700;
  font-size: 1.4vh !important;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  background-color: #2f80ed;
  width: 10.42vw;
  height: 3.2vh !important;
  padding: 0 !important;
  margin-top: 1.4vh;
}
button.BusinessBack-btn.btn.btn-outline-primary,
.change-password-footer .btn-outline-primary {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
  font-size: 1.4vh !important;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  width: 10.42vw;
  height: 3.2vh !important;
  margin-top: 1.4vh;
  border: 1px solid #2f80ed;
  border-radius: 4px;
  padding: 0 !important;
}
button.add-location {
  font-weight: 700 !important;
  font-size: 1.4vh !important;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  background: #2f80ed;
  border-radius: 4px !important;
  width: 6.95vw !important;
  height: 3.2vh !important;
  margin-bottom: 3.2vh;
}
.change-password-footer .btn-outline-primary {
  margin: 0;
}
.modal-content .change-password-footer {
  padding: 2.1vh 2.85vw 3.2vh 0;
}
button.add {
  background-color: transparent;
  border: none !important;
  padding: 0;
  margin: 0 0 0 0.84vw;
}
.mt-26 {
  margin-top: 2.6vh !important;
}
button.delete {
  border-radius: 50% !important;
  padding: 3px 10px 3px 10px;
  background-color: white;
  color: #2f80ed;
  border: 1px solid #2f80ed;
}
/****for address heading**/
div.add-div {
  margin-left: 0.7rem !important;
}
/*****for location screen****/
.Card2 {
  width: 698px;
  padding: 20px;
  border-radius: 10px;
  display: block !important;
  margin-top: 40px;
}

select {
  /* styling */
  background-color: white;
  border: thin solid blue;
  display: inline-block;
  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.locationlabel {
  margin-left: -1rem;
}

select#exampleSelect.location.form-control {
  width: 22.57vw;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,<svg fill='blue' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
}

select#exampleSelectNew.location.form-control {
  width: 19.57vw;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,<svg fill='blue' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
}
/*******input field and button class***/
.Add-field-button {
  display: -webkit-box !important;
}
.Add-field {
  /* position: absolute; */
  position: absolute;
  bottom: 5rem !important;
}
.Add-client-field {
  position: absolute;
  top: -25px;
}
.mb-10 {
  margin-bottom: 1vh !important;
}
/* 
input[type=file]::-webkit-file-upload-button {
    background: none;
    border: none;
    padding: 0;
    color: blue;
  } */

/* input[type="file"] {
  cursor: pointer;
  width: 100%;
  display: flex;
  margin: 0 1.5rem 0 1rem;
  visibility: hidden;
  height: 0 !important;
} */

.ml-8 {
  margin-left: 0.56vw;
}
.mt-8 {
  margin-top: 0.8vh;
}
.mt-21 {
  margin-top: 2.1vh !important;
}
input#editprintimage[type="file"] {
  width: 137px;
}

input#editPicProfile[type="file"] {
  color: rgba(0, 0, 0, 0);
}
input#editBusinessProfile[type="file"] {
  color: rgba(0, 0, 0, 0);
  width: 52% !important;

  /* display: none; */
}

input#editBusinessProfile:before {
  height: 32px;
  font-size: 1.6vh;
  line-height: 3.2vh;
  content: "Upload Company Logo" !important;
  display: inline-block;
  background: none !important;
  color: #2f80ed !important;
  text-decoration: underline !important;
  padding: 0 0px;
  border: none !important;
  text-align: center;
  /* position: absolute; */
  bottom: 8rem;
}

input#editPicProfile:before {
  height: 32px;
  font-size: 1.5vh;
  line-height: 3.2vh;
  content: "Upload Images" !important;
  display: inline-block;
  /* background: white; */
  color: #2f80ed !important;
  /* text-decoration: underline !important; */
  padding: 0 0px;
  text-align: center;
  position: absolute;
  right: 34.3rem;
  bottom: 6.5rem;
}

input[type="file"]::-webkit-file-upload-button {
  display: none;
}
/******For upload photo in Buisnes screen***/

/* .Input-Upload>input[type=file]::-webkit-file-upload-button{
    display: none;

} */
.Upload-Photo > input[type="file"]:before {
  height: 24px;
  font-size: 1.6vh;
  line-height: 3.2vh;
  text-align: center;
  content: "Upload Company Logo";
  display: inline-block;
  /* background: white; */
  color: blue;
  padding: 0 0px;
  text-align: right;
  width: 52% !important;
}
div.UploadImg {
  width: 698px;
  padding: 0px !important;
  border-radius: 10px;
  display: block !important;
  margin-top: 0px !important;
}
.Input-Upload {
  display: flex;
}
div.Upload-Photo {
  display: contents;
  padding: 1rem;
}
/***For No file choosen box**/
/* input#examplephoto.input.form-control-file{
    box-shadow: 0px 0px 5px rgb(0 0 0 / 25%) !important;
  
    width: 46% !important;
} */
input[type="file"]::after {
  background: red !important;
}
/****Location delete button***/
.delete-location {
  position: absolute;
  right: 30px;
  top: -16em;
}

.imageBorder > img {
  width: 150px;
  height: 150px;
  border: none;
  border-radius: 5px;
}

.Upload-Img {
  display: flex;
  margin-left: -8rem;
}
/***for upload image text**/
.UploadImageText {
  margin: 5px;
}
.edit-iconn {
  height: 23px;
  border-radius: 22px;
  background: white;
  color: black;
  cursor: pointer;
}
/**for delete icon in summary screen**/
.delete-iconn {
  height: 23px;
  border-radius: 22px;
  background: white;
  margin-left: 0.6rem;
  color: black;
  cursor: pointer;
}
div.UploadImg {
  width: 698px;
  padding: 20px;
  border-radius: 10px;
  display: block !important;
  margin-top: 40px;
}
/* div.Buis-Button{
    margin-top: 3rem !important
} */

/***for gallery screen***/
.upload__image-wrapper {
  margin-top: -1rem;
}
.Buis-Button-Gallery {
  margin-bottom: 0.5rem !important;
}

/***Summary form***/
.Summary-Form {
  margin-left: 12rem;
  margin-right: 12rem;
}
/****For Gallery screen***/

/***for text removal***/

.ant-upload.ant-upload-select-picture-card {
  background-color: white !important;
  border: 0px !important;
}

span.anticon.anticon-plus {
  border: 1px solid blue;
  border-radius: 50%;
  color: blue;
  font-size: 0.9vh;
  padding: 4px;
}
.upload-add {
  display: inline-flex;
  flex-flow: row-reverse !important;
  position: absolute;
}
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: none !important;
}
/**top heading***/
.jumbotron {
  margin-bottom: 0 !important;
  background-color: white !important;
  border-radius: none !important;
}
/***for gallery card***/
.MainDiv {
  display: flex !important;
  justify-content: center !important;
}

.delete-photo {
  width: 1.12vw;
  height: 1.6vh;
}
.addgallry-photo {
  height: 16px;
  border-radius: 22px;
  color: black;
  cursor: pointer;
  margin-top: -10px;
  margin-left: 19px;
}
.add-gallery-photo {
  width: 1.12vw;
  height: 1.12vw;
  cursor: pointer;
}
.heading-profile {
  font-size: 2.4vh;
  line-height: 2.2vh;
  letter-spacing: -0.408px;
  color: #333333;
  margin-top: 5vh;
  text-align: center;
  margin-bottom: 5.2vh;
}

/* locations css */

.address-para {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.6vh;
  line-height: 2.4vh;
  color: #333333;
  margin-bottom: 1.6vh;
}

.location-edit-icon {
  width: 1.05vw;
  height: 1.05vw;
  cursor: pointer;
}
/**for delete icon in summary screen**/
.location-delete-icon {
  width: 1.05vw;
  height: 1.05vw;
  margin-left: 0.7vw;
  cursor: pointer;
}
.location-icon {
  display: flex;
  justify-content: flex-end;
}

/*********Output Company Profile Page CSS ********/
div.heading-Coprofile h4 {
  text-align: center !important;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
}
div.MainCompany {
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

div.CompanyPrfinaldiv {
  width: 48.48vw;
  padding: 1.7vh 1.12vw 3.1vh;
}

img.ComapnyImge {
  max-width: 18.5vw;
  max-height: 20vh;
  object-fit: contain;
  border-radius: 10px;
  margin-top: 1.2vh;
}

.Imagediv p {
  font-weight: 600;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: -0.408px;
  color: #4f4f4f;
  margin-bottom: 0;
}
.Imagediv span {
  font-size: 1.4vh;
  line-height: 2.2vh;
  letter-spacing: -0.408px;
  color: #4f4f4f;
}

.whatwedo-div p {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  margin-bottom: 0.8vh;
  margin-top: 1.6vh;
}
.whatwedo-div1 p {
  font-weight: 600;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: -0.408px;
  color: #4f4f4f;
  margin-bottom: 1.6vh;
}
.add-text,
.add-text .lowercases {
  font-weight: 400;
  font-size: 1.6vh;
  line-height: 2.2vh;
  letter-spacing: -0.408px;
  color: #4f4f4f;
  margin-bottom: 0.8vh;
}
.add-text .lowercases {
  margin-bottom: 0;
}
.whatwedo-div1 {
  margin-top: 3.3vh;
}
.whatwedo-div1 span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2.4vh;
  color: #828282;
  text-align: justify;
}

.whatwedo-div span {
  font-family: "Roboto", sans-serif;
  font-size: 1.4vh;
  line-height: 2.4vh;
  color: #828282;
  margin-left: 0 !important;
}

p.companyLogodiv {
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 2.4vh;
  text-align: center;
  line-height: 2.2vh;
  color: #4f4f4f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.client-innfo-container {
  position: relative;
  border: 0.25px solid #333333;
  width: 13.89vw;
  height: 10.42vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5vh 0;
}
div.companyLogodiv.row img {
  width: 13.88vw;
  height: 15vh;
  object-fit: contain;
  border-radius: 4px;
}

.Buis-Button-Company {
  margin-top: 1.8vh;
  display: flex;
  justify-content: flex-end;
}

.client-image-upload {
  font-family: "Roboto", sans-serif;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #2f80ed !important;
  margin-bottom: 0 !important;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 0.7vw;
}
.Input-Upload div {
  flex-basis: inherit !important;
}
.CompFinaledi {
  width: 1.05vw;
  height: 1.05vw;
  float: right;
}
/***for compny submit screen***/
div.Editicon-Company {
  display: flex;
  flex-direction: row-reverse;
}

.gallery-section {
  max-width: 13.89vw;
  max-height: 20vh;
  display: inline-block;
  /* object-fit: cover !important; */
}
.gallery-img .gallery-section {
  width: 6.95vw;
  height: 6.95vw;
  object-fit: contain;
  border-radius: 4px;
}
div.EdirPreviewBtn {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -14.1vw;
  top: 3.7vh;
}

button.ButtonEditpre.btn.btn-primary {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700;
  font-size: 1.4vh;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  background: #2f80ed;
  border-radius: 16px;
  width: 10.42vw;
  height: 3.2vh;
  margin-bottom: 1.6vh;
  padding: 0;
  margin-left: 0;
}

.Card1 > .PreviewCompany {
  width: 1000px;
}

li.CompanyPreviewitems.nav-item {
  margin-left: 0rem !important;
}
/***for company final screen***/
div.Companydata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  margin: 0 auto;
}
h4.profile-company {
  margin-bottom: -3rem;
  margin-top: 5rem;
  text-align: center;
}
.oodl-ul {
  padding-left: 0px;
}
.oodl {
  font-family: "Roboto", sans-serif;
  font-size: 1.6vh;
  line-height: 2.4vh;
  color: #333333;
  padding: 0.8vh 0px;
}

.whatwedo-div p.loc2 {
  margin-top: 0px;
}
.loc2 p {
  margin-top: 8px;
  margin-left: 0;
}

button.close-modal {
  font-size: 24px;
  position: absolute;
  top: 13px;
  right: 21px;
  z-index: 10;
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.View-Link {
  color: white;
  margin-bottom: 0;
}

.gallery-delete-icon {
  width: 1.12vw;
  height: 1.12vw;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 0.28vw;
  right: 0.28vw;
}
.imageboxx {
  width: 6.95vw;
  height: 6.95vw;
  position: relative;
}

.certi-delete-icon {
  display: flex;
  border: none !important;
}

.certificate-delete-icon {
  margin-left: 0.56vw;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
}

.lowercase {
  font-family: "Roboto", sans-serif;
  font-weight: 400 !important;
  font-size: 1.4vh !important;
  line-height: 2.4vh !important;
  color: #333333 !important;
  letter-spacing: 0 !important;
}
.small-spinner {
  width: 10px;
}

.form-reset {
  background: transparent !important;
  color: red !important;
  border: 1px solid red !important;
  border-radius: 37px !important;
  padding: 1px !important;
  width: 97px !important;
}

.progress-bar {
  background-color: #27ae60 !important;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.progress {
  width: 34.73vw;
  height: 0.8vh !important;
  background-color: #c4c4c4 !important;
  margin-left: 6.88vw;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.1);
}
.activeStep {
  font-weight: 600;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #333333;
  margin-bottom: 0;
}
.stepsArray {
  justify-content: space-around;
  margin: 0.8vh 0 0;
}

.certificate-div {
  width: 30.56vw;
  height: 8.4vh;
  background: #f2f2f2;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.certificate-div .certificate-form .form-control {
  width: 23.13vw;
}
.certificate-div .certificate-form .Add-button {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.4vh !important;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  background-color: #2f80ed;
  width: 3.48vw !important;
  height: 3.2vh !important;
  padding: 0 !important;
  margin-top: 1vh;
  margin-left: 0.49vw;
}
.bg-transparent {
  background-color: transparent !important;
}
.certificate-form {
  padding: 1.8vh 1.32vw;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  justify-content: flex-start;
}
.gallary-upload-label {
  font-family: "Roboto", sans-serif;
  color: #2f80ed !important;
}
.Add-button {
  width: 3.48vw;
  height: 3.2vh;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.4vh !important;
  line-height: 1.6vh !important;
  letter-spacing: 0.02em;
  background: #2f80ed;
  padding: 0 !important;
  margin-left: 1.39vw;
}
.add-client-div .add-client-form {
  position: relative;
}

.add-location {
  height: 32px !important;
  background: #2f80ed;
  border-radius: 4px !important;
  font-weight: bold !important;
  font-size: 1.3vh !important;
  margin-top: 7px !important;
  width: 100px !important;
}

.add-location-button {
  margin-bottom: 2vh;
  display: inline-block;
  line-height: 0;
}
.modal-dialog {
  margin-top: 34px !important;
}
.close-x {
  position: absolute;
  top: 5.33spx;
  right: 8.33px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.location-div {
  width: 45.98vw;
  height: 47.4vh;
  background: #f2f2f2;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.location-form {
  padding: 20px 20px 0px 20px;
}
.location-close-x {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  float: right;
  margin: 0.8vh 0.93vw 0 0;
}
.location {
  height: 4.8vh !important;
}
.drag {
  font-size: 1.4vh;
  line-height: 1.7vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
}
.null {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  margin-bottom: 0;
}
.mt-42 {
  margin-top: 4.2vh !important;
}
.add-client-form {
  padding: 1.8vh 1.32vw;
}
.client-elem .form-control {
  width: 23.13vw;
}
.client-elem {
  align-items: center;
}
.add-client-div {
  width: 43.2vw;
  height: 8.4vh;
  background: #f2f2f2;
  box-shadow: 0px 0px 6px 3px rgb(0 0 0 / 25%);
  border-radius: 10px;
  margin-top: 2vh;
}
.add-client-images {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.add-client-images1 {
  width: 13.89vw;
  height: 10.42vw;
  border: 0.25px solid #333333;
  margin: 0 0 2.5vh 0;
  object-fit: contain;
}
p.companyLogodiv1 {
  width: 13.89vw;
  height: 10.42vw;
  border: 0.25px solid #333333;
  font-weight: 500;
  font-size: 2.4vh;
  line-height: 2.9vh;
  letter-spacing: -0.408px;
  color: #4F4F4F;
  overflow: hidden;
  margin: 0 0 2.5vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-client-images12 {
  width: 10.42vw;
  height: 10.42vw;
  margin: 0 1.74vw 2.5vh 0;
  object-fit: contain;
}
p.companyLogodiv12 {
  width: 10..42vw;
  height: 10.42vw;
  font-weight: 500;
  font-size: 2.4vh;
  line-height: 2.9vh;
  letter-spacing: -0.408px;
  color: #4F4F4F;
  overflow: hidden;
  margin: 0 1.74vw 2.5vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.client-delete-icon {
  width: 1.39vw;
  height: 1.39vw;
  cursor: pointer;
  position: absolute;
  top: 0.7vw;
  right: 0.7vw;
}
.client1-delete-icon {
  height: 20px;
  border-radius: 22px;

  color: black;
  cursor: pointer;
  bottom: 0;
  position: absolute;
  top: 10px;
  right: 23px;
}
.Gallery {
  margin-top: 1.6vh !important;
}
.certificates-2 {
  font-family: "Roboto", sans-serif;
  font-size: 1.4vh !important;
  line-height: 2.4vh !important;
  color: #828282 !important;
}

.mb-8 {
  margin-bottom: 0.8vh !important;
}
.mt-18 {
  margin-top: 1.8vh !important;
}

.gall-ry {
  display: grid !important;
  grid-template-columns: 1fr 1fr auto;
  grid-row-gap: 2.5vh;
}
.gallery-img {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, 6.95vw);
  grid-gap: 1.12vw;
}
.add-client-parent {
  /* margin-top: 2rem; */
  position: relative;
}


/* 
p.companyLogodiv1:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
  font-size: 14px;
} */

/* p.companyLogodiv:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
  font-size: 14px;
} */
.text-capitalize::first-line {
  text-transform: capitalize !important;
}

.bold {
  font-weight: 600;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  margin-bottom: 1.6vh;
}

.delete-iconn-summary {
  width: 1.39vw;
  height: 1.39vw;
  margin-left: 0.73vw;
  cursor: pointer;
}
.loc1 {
  font-size: 1.8vh !important;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  font-family: "LATO";
  font-weight: 600;
}

.modal::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.add-client-images4 {
  height: 150px;
  width: 200px;
  /* margin: 8px 13px; */
  border: 5px solid #ffff !important;
  display: inline-block;
  border-radius: 8px;
  margin: -6px;
  /* object-fit: cover; */
  /* margin: 21px 9px !important; */
  margin-top: 7px;
}
