/* .Assignedteam{
  margin: 4rem;
} */
   .Organsisationheader h4 {
    font-weight: normal;
    font-size: 3rem !important;
    color: #333333;
    margin-left: -29rem;
    margin-top: 1rem;

  }
  .Organisationcontainer1 {
    width: 90%;
    /* margin: 7% auto;*/   
    transform: scale(0.5);
  }
  .Unassigned-ul{
      display: block !important;
  margin-left: -2rem;
   
  }
hr.Unassigned-hr{
  margin-top: 3rem;
    width: 174px;
}
  .Unassignd-text{
    font-size: 24px;
    line-height: 28px;
    color: #333333;
    margin-left: 3rem;
}
.assignd-text{
  color: #333333;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: -1rem;
  font-size: 24px;
  line-height: 28px;
}

.Assignedd-Li{
  display: flex;
  /* margin-bottom: 11rem; */
  margin: 0;
  padding: 0.2em;

}
 .Assigned-ul{
     display: flex;
     margin-top: -8rem;
     margin-left: -28rem;
 }
 .assignedli{
   margin-top: 4rem;
   margin-left: -2rem;
 }
 .assignedli1{
  margin-top: 4rem;
  margin-left: -3.3rem;

}
 .org-tree {
  margin-top: -13rem;
  margin-left: -17rem;
}
 
  .org-tree1 ul {
    padding-top: 20px;
    position: relative;
    transition: all 0.5s;
  }
  
  .org-tree1 li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 12rem 7rem 0px;
  
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  /*We will use ::before and ::after to draw the connectors*/
  
 
  
  .org-tree1 li:only-child::after,
  .org-tree1 li:only-child::before {
    display: none;
  }
  
  .org-tree1 li:only-child {
    padding-top: 0;
  }
  
  .org-tree1 li:first-child::before,
  .org-tree1 li:last-child::after {
    border: 0 none;
  }
 
  .org-tree1 li:last-child::before {
    border-radius: 0 5px 0 0;
  }
  .org-tree1 li:first-child::after {
    border-radius: 5px 0 0 0;
  }
  
  
  

 
  
  .org-tree1 li a {
    border: 1px solid #ccc;
    padding: 5px 10px;
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
  
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
  
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  .org-tree1 li a:hover,
  .org-tree1 li a:hover + ul li a {
    background: #c8e4f8;
    color: #000;
    border: 1px solid #94a0b4;
  }
  
  /* .org-tree1 li a:hover + ul li::after,
  .org-tree1 li a:hover + ul li::before,
  .org-tree1 li a:hover + ul::before,
  .org-tree1 li a:hover + ul ul::before {
    border-color: #94a0b4;
  } */
  
  .OrgChart1 {
    /* position: relative;
    padding-top: 50px;
    position: relative; */
    padding-top: 2.5rem;
    border: 0.5px solid #828282;
    border-radius: 100px;
    margin: 0rem;
    width: 150%;
    height: 8rem;
    /* width: 300px; */
  }
  /**for unassigned**/
  .OrgChart1 .Orgimageun {
    border-radius: 50%;
    position: absolute;
    left: 19%;
    transform: translateX(-50%);
    top: 4.5%;
    z-index: 100;
  }
 
  .OrgChart1 .Orgimageun img {
    border-radius: 50%;
    width: 114px;
    height: 112px;
    /* border: 13px solid #96f6b7; */
  }
  /**for assigned**/
  .OrgChart1 .Orgimage1 {
    border-radius: 50%;
    position: absolute;
    left: 12%;
    transform: translateX(-50%);
    top: 10%;
    z-index: 100;
  }
  .OrgChart1 .Orgimage1 img {
    border-radius: 50%;
    width: 114px;
    height: 112px;
    /* border: 13px solid #96f6b7; */
  }
  .OrgChart1 .OrgChart1-body {
    height: 56px;
    text-align: center;
    width: 300px;
    top: -4rem;
    padding-top: 60px;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
  }
  .OrgChart1 .OrgChart1-body h4 {
   border-bottom: 0.5px solid #333333;
   margin-bottom: 4px;
   color: #333333;
  }
  
  .OrgChart1 .OrgChart1-body h2 {
    font-weight: 600;
    line-height: 20px;
  }
  .OrgChart1 .OrgChart1-body p {
    line-height: 20px;
    font-size: small;
  }
  .OrgChart1 .OrgChart1-footer {
    padding: 3px;
    background: #96f6b7;
    text-align: center;
    bottom: 0;
    position: relative;
    width: 295px;
    border-radius: 16px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
  }
  .OrgChart1 .OrgChart1-footer img {
    width: 25px;
    margin: 5px;
  }
  

  /*****for Unassigned user******/
 
  .org-tree-un{
    margin-top: -12rem;
    margin-left: -5rem;
  }
 
  .org-tree-un ul{
    padding-top: 20px;
    position: relative;
    transition: all 0.5s;
  }
  
  .org-tree-un li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  /*We will use ::before and ::after to draw the connectors*/
  
 
  
  .org-tree-un li:only-child::after,
  .org-tree-un li:only-child::before {
    display: none;
  }
  
  .org-tree-un li:only-child {
    padding-top: 0;
  }
  
  .org-tree-un li:first-child::before,
  .org-tree-un li:last-child::after {
    border: 0 none;
  }
 
  .org-tree-un li:last-child::before {
    border-radius: 0 5px 0 0;
  }
  .org-tree-un li:first-child::after {
    border-radius: 5px 0 0 0;
  }
  
  
  

 
  
  .org-tree-un li a {
    border: 1px solid #ccc;
    padding: 5px 10px;
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
  
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
  
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  .org-tree-un li a:hover,
  .org-tree-un li a:hover + ul li a {
    background: #c8e4f8;
    color: #000;
    border: 1px solid #94a0b4;
  }

  /* .Unassigned-ul li{
     margin:2rem;
  } */