.table {
  border: 1px solid rgba(128, 128, 128, 0.015);
  border-radius: 12px;
}
.admin-table.buyer-table th {
  max-width: 7vw !important;
  min-width: 7vw !important;
  padding: 1vh !important;
  vertical-align: middle;
}
.heading-row th:first-child {
  border-top-left-radius: 10px !important;
  max-width: 5vw !important;
  min-width: 5vw !important;
}
.heading-row th:last-child {
  border-top-right-radius: 10px !important;
  max-width: 13vw !important;
  min-width: 13vw !important;
}
.heading-row {
  background: #e0e0e0;
  font-size: 1.65vh;
  text-align: center;
}
.data-row {
  background: white;
  text-align: center;
}

.type-btn {
  width: 5vw;
  font-size: 1.65vh !important ;
}
.btn {
  font-size: 1.65vh !important;
}
.btn-data {
  display: flex;
  justify-content: space-around;
}
.heading-row > th {
  color: #776d6d !important;
  /*     
    border-radius: 10px 0px 0px 0px;
    width:49px;
    height:40px; */
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #f2f2f2 !important;
  vertical-align: middle;
}
.table {
  padding: 3vh !important;
  color: grey !important;
  border-radius: 20px !important;
}

.nav-item {
  cursor: pointer;
  margin-left: 4.8rem;
}
li.Wovennavtab.nav-item {
  cursor: pointer;
  margin-left: 3rem !important;
}
.nav-tabs .nav-link.active {
  border-bottom: none !important;
  background: none !important;
  font-weight: bold;
  font-size: 1.8vh;
  line-height: 2.3vh;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.5);
}
/* .nav-tabs .nav-link{
    border: none !important;
} */
.nav-tabs {
  border-bottom: none !important;
  background: #e0e0e0;
}
.nav-item > a {
  color: grey;
}
ul.nav.nav-tabs {
  margin-left: 0 !important;
  width: 100%;
  height: auto;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.col-sm-10 {
  padding: 0 !important;
}

.col-sm-2 {
  padding-right: 0 !important;
}

.badge-number {
  background-color: #eb5757 !important;
  border-radius: 49px !important;
}

/* th.newReqSdate{
    background: #E0E0E0;
border: 1px solid #F2F2F2;
border-radius: 10px 0px 0px 0px;
width:114px;
height:40px;
} */

/* th.newReqcName{
    background: #E0E0E0;
border-right: 1px solid #F2F2F2;
border-radius: 10px 0px 0px 0px;
width:114px;
height:40px;

}
th.newRequname{
    background: #E0E0E0;
border: 1px solid #F2F2F2;
border-radius: 10px 0px 0px 0px;
width:114px;
height:40px;
}
th.newReqlink{
    background: #E0E0E0;
border: 1px solid #F2F2F2;
border-radius: 10px 0px 0px 0px;
width:114px;
height:40px;
}
th.newReqType{
    background: #E0E0E0;
border: 1px solid #F2F2F2;
border-radius: 10px 0px 0px 0px;
width:114px;
height:40px;
}

th.newReqAction{
    background: #E0E0E0;
border: 1px solid #F2F2F2;
border-radius: 10px 0px 0px 0px;
width:187px;
height:40px;

} */
button.typereject-btn.btn.btn-danger {
  background: #eb5757;
  border-radius: 4px;
  width: 5vw;
  height: 32px;
  color: #ffffff;
  text-align: center;
  padding: 0px !important;
}

button.typeblock-btn.btn.btn-warning {
  background: #f2994a;
  border-radius: 4px;
  width: 5vw;
  height: 32px;
  color: #ffffff;
  text-align: center;
  padding: 0px !important;
}

button.typeUnblock-btn.btn.btn-success {
  background: #27ae60;
  border-radius: 4px;
  width: 5vw;
  height: 32px;
  color: #ffffff;
  text-align: center;
  padding: 0px !important;
}
button.typeaccept-btn.btn.btn-primary {
  border-radius: 4px;
  width: 5vw;
  height: 32px;
  color: #ffffff;
  padding: 0px !important;
  text-align: center;
  background: #2f80ed;
}

a.View-Linkreq {
  text-decoration: underline !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white !important;
  opacity: 1;
}

.setCommission {
  height: 97px !important;
  /* border: 1px solid black; */
  border-radius: 6px;
  width: 100px !important;
}
.newEntry {
  font-size: 1.4vh;
  line-height: 3;
}
.fix-width {
  height: 1.9vh;
  width: 1.9vh;
  margin-left: 1vh;
}
.commissionForm {
  margin-top: 10px;
  width: 50px !important;
  height: 3vh !important;
  background: #f2f2f2 !important;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}

.button-commission {
  height: 2.9vh;
  width: 7vw !important;
  font-size: 1.4vh !important;
  border-radius: 0px 0px 4px 4px !important;
  padding: 0rem 0.75rem !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  margin-left: 0px !important;
  margin-right: 7px;
}
.flex-form {
  display: flex;
  justify-content: center;
  height: 5.8vh !important;
}
.div-comm {
  width: 7vw !important;
  background: #e0e0e0;
  border-radius: 4px 4px 0px 0px;
}
.gray-row {
  background-color: #e0e0e0;
  text-align: center;
}
.days {
  margin-top: 15px;
  font-size: 1.4vh;
}
.commissionRate {
  height: 3vh !important;
  padding: 0px !important;
  background: #f2f2f2 !important;
  border: 1px solid #f2f2f2 !important;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.1) !important;
}
