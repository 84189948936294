/**for complete Yarn screen**/
.Yarn {
  background: white;
}
/***table row**/

tr.row-yarn {
  background-color: #e0e0e0 !important;
  color: #696969;
}
/**add table row div**/
.Add-tablerow {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center !important;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 690px;
  height: 107px;
}

.fabric-field {
  width: 500px !important;
  height: 48px !important;
  margin-right: 1rem !important;
}
/***Add button **/
.Add-row-Buttn {
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  /* identical to box height */
  text-align: center !important;
  letter-spacing: 0.02em !important;
}
/***input field of fabric**/
input.ant-input.fabric-field {
  width: 500px !important;
  height: 48px !important;
  margin-right: 1rem !important;
}
/**for yarn table**/
table#tab_logic.table.table-bordered.table-hover.Yarn {
  border-radius: 10px 10px 0px 0px !important;
}

/*for delete icon***/
.Delete {
  height: 23px;
  border-radius: 22px;
  background: white;
  color: black;
  cursor: pointer;
  /* position: absolute; */
  right: 12rem;
  top: 15rem;
}
/****for Popup delete button***/
button.YesDeleteIt {
  height: 3.2vh !important;
  width: 7.5vw !important;
font-size: 2vh;
  color: white !important;
  background: #2f80ed !important;
  border-radius: 4px !important;
  border: none !important;
}
button.Notdelted {
  height: 3.2vh !important;
  width: 7.5vw !important;
  font-size: 2vh;
  background: #f2f2f2 !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  color: #2f80ed !important;
  border: 1px solid #2f80ed !important;
}
h6.AlertNotheading {
  margin-bottom: 32px !important;
}
.custom-ui{
  padding:1vh;
  text-align: left !important;
}
.custom-ui button {
  /* height: 32px !important;
    width: 150px !important;
    background: #2F80ED !important; */

  margin-left: 1rem !important;

  /* border-radius: 4px !important; */
}
.custom-ui h6{
  font-size: 1.9vh;
}
/***For header and yarn space**/

/****for knit screen***/
.Knit {
  margin: 11.6vh auto 4.2vh;
  padding: 1.8vh 1.19vw;
  width: 69.45vw;
  background: #ffffff;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 90vh;
}

.Knit3 {
  margin: 11.4vh auto 4.2vh;
  padding: 1.8vh 1.19vw;
  width: 69.45vw;
  background: #ffffff;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 90vh;
}
.Knit1 {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 1000px;
  margin: 20px auto;
  text-align: center;
  background-color: white !important;
}
.nav-tabs {
  margin: 2rem 0rem 0rem;
}
#Table {
  margin-top: 1.8vh;
}
.table td,
.table th {
  border-top: 0px !important;
}
#Table table {
  width: 62%;
  padding: 10px;
  border-collapse: collapse;
  border-radius: 10px 10px 0px 0px !important;
  overflow-y: scroll !important;
  margin-bottom: 0;
}
#Table tbody {
  overflow: auto !important;
}

/* .Scroll{
    overflow-y:scroll !important;
    overflow-x: hidden !important;
    height: 20rem!important;
  } */
/* #Table td {
  width: 100px;
  height: 50px;
  padding: 10px;
  background: #f2f2f2 !important;
  border-right: 3px solid white;
  border-bottom: 3px solid white !important;
} */

.yarn-table th.sno {
  background: #e0e0e0;
  border-radius: 10px 10px 0px 0px;
  width: 6.95vw;
  min-width: 6.95vw;
  height: 4vh;
  border-right: 2px solid #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #696969;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
.yarn-table th.storage {
  background: #e0e0e0;
  border-radius: 10px 10px 0px 0px;
  width: 34.73vw;
  height: 4vh;
  border-right: 2px solid #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #696969;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
th.yarn-type-blend{
  background: #e0e0e0;
  border-radius: 10px 10px 0px 0px;
  width: 15vw;
  min-width: 15vw;
  height: 4vh;
  border-right: 2px solid #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #696969;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
.yarn-table tr{
  display: flex;
}
.yarn-table tr td:first-child{
  background: #F2F2F2;
  width: 6.95vw;
  min-width: 6.95vw;
  height: 5vh;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #4F4F4F;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
.yarn-table tr td:nth-child(2),.sus-name-td,.sus-input-td{
  background: #F2F2F2;
  width: 34.73vw;
  height: 5vh;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #4F4F4F;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  position: relative;
}
td.yarn-type-blend,.yarn-name-blend{
  background: #F2F2F2;
  width:15vw !important;
  min-width:15vw !important;
  height: 5vh;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #4F4F4F;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  position: relative;
}
.blen-table{
  max-width: 68vw !important;
  overflow: auto;
}
.sus-table-sr{
  background: #F2F2F2;
  width: 4vw !important;
  height: 5vh;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.8vh;
  line-height: 2.1vh;
  letter-spacing: 0.02em;
  color: #4F4F4F;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  position: relative;
}
.sus-name-td,.sus-input-td{
  width:30vw !important;
}
.DeleteProductcat1{
  /* position: absolute;
  left: 1vw; */
  width: 0.82vw;
  height: 1.05vw !important;
}

#Add-Fields {
  display: inline-flex;
}
#Add-Fields label {
  margin-right: 0.56vw;
  margin-bottom: 0 !important;
}

#Add-Fields input {
  width: 34.73vw;
  height: 4.8vh !important;
  background: #ffffff;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 0.56vw;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
}
#Add-Fields input::placeholder {
  font-size: 1.6vh !important;
  line-height: 1.9vh !important;
  letter-spacing: 0.01em !important;
  color: #c4c4c4 !important;
}

/**for placeholder***/
input#storage::placeholder {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  padding-left: 8px;
  color: #c4c4c4;
}

#Add-Fields button {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.4vh;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  background: #2f80ed;
  border-radius: 4px;
  width: 10.42vw;
  height: 3.2vh;
  border: none;
  color: #fff;
  margin-top: 0.8vh;
}
p.heading-fabric {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  margin-bottom: 0.8vh;
}
.active.nav-link{
  text-decoration: underline  !important;
}
form.MetaForm {
  width: 47.92vw;
  background: #ffffff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 1.3vh 0.7vw 1.9vh 1.53vw;
}

/***Alert Confirmation box***/
.react-confirm-alert {
  height: 22vh !important;
  width: 28vw !important;
  background: #f2f2f2 !important;
  vertical-align: middle !important;
  justify-content: center !important;
  text-align: center !important;
  color: #828282 !important;
}

.Alert {
  color: #eb5757 !important;
  text-align: left;
  font-size: 1.9vh !important;
  margin-bottom: 1vh;
  font-weight: bold !important;
  line-height: 22px !important;
  letter-spacing: 0.01em !important;
}
.Alrtbuttons {
  display: flex;
  float: right;
  margin-right: 1.5rem;
}
.content-yarn{
  width:34.73vw;
}
.sustain-btn{
  white-space: nowrap;
}
.no-background{
  background-color: #ffffff !important;
}
.sus-serial-no{
  width: 4vw !important;
  margin-right:2px;
  text-align:center;
}
.sus-input-box{
  height: 3vh !important;
  margin-left: 5px;
  padding-left:1vh;
  width: 100%;
  border-radius: 5px;
  outline: none !important;
  border:none !important;
}
.gray-back{
  background-color: #f2f2f2;
  height:4.8vh;
}
.blend-top{
  box-shadow: 0 0 5px 3px #0000001A;
padding:5px 5px 15px 10px;
}
.blends input ,.blends select{
  min-width: 12vw;
  max-width: 12vw;
  max-height: 5vh;
  padding-left:4px;
  height: 5vh;
  border:1px  solid #828282 !important;
  border-radius: 3px;
}
select.blend-yarn{
  width: 12vw;
  max-height: 5vh;
  height: 5vh;
  font-size: 1.5vh;
  color: #4f4f4f;
  border:1px solid  #828282 !important;

}
select.blend-yarn option{
  color:#828282;
}
.blends p{
  color: #4F4F4F;
  margin-bottom: 3px;
  font-family: 'lato';
  font-size: 1.8vh;
}
.add-blends{
  
  margin-left: 10px;
}
.pt-10{
  padding-top:10px;
}
button.btn:active{
color: #fff  !important;
}