.Main1 {
  justify-content: center !important;
}

/***card select button**/
.Card-Button {
  background: #2f80ed !important;
  border-radius: 16px !important;

  width: 6.25rem !important;
  border: 1px solid #2f80ed !important;
}
.button-right {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.SignUP-Card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
.card-body {
  height: 100%;
  border-radius: 10px !important;
}
.card-text {
  font-size: 0.875rem !important;
  letter-spacing: -0.40799999237060547px;
  color: #828282;
  text-align: center !important;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  hyphens: auto !important;
  line-height: 22px;
  letter-spacing: -0.408px;
}
.card-title {
  font-size: 1.125rem;
  color: #4f4f4f;
  text-align: center;
  font-weight: 600;
  line-height: 22px;
  /* or 122% */
  color: #4f4f4f;
  font-family: "Lato", sans-serif;
}

.SignDasText {
  font-size: 0.875rem !important;

  color: #828282;
  text-align: left !important;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  hyphens: auto !important;
  line-height: 22px;
  font-family: "Lato", sans-serif;
}
.SignDastitle {
  font-size: 1.125rem;
  color: #4f4f4f;
  text-align: left;
  font-weight: 600;
  line-height: 22px;
  /* or 122% */
  color: #4f4f4f;
}

.heading {
  text-align: center;
  margin-top: 6rem;
  font-size: 24px;
  font-family: "Lato", sans-serif;
}
.opactiy-0 {
  opacity: 0;
}
.sub-heading {
  margin: 1rem;
  /* margin-left: 11rem; */
  font-size: 1.125rem;
  color: #4f4f4f;
}

.Card-Button {
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: 0.02em;
}

button.SignUp-Submit.btn.btn-primary {
  font-weight: 700;
  font-size: 1.4vh !important;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  background: #2f80ed;
  border-radius: 4px;
  width: 7.5vw;
  height: 3.2vh;
  padding: 0;
}
button.SignUp-Back.btn.btn-outline-primary {
  font-weight: 700;
  font-size: 1.4vh !important;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  border-radius: 4px;
  width: 7.5vw;
  height: 3.2vh;
  padding: 0;
  border: 1px solid #2f80ed;
}

input#exampleAddress.form-control::placeholder {
  font-size: 12px;
  /* line-height: 14px !important; */
  text-align: right;
  letter-spacing: 0.01em;
  color: #c4c4c4;
  padding-top: 4px !important;
}

:root {
  --textareaexampleaddHeight: !important;
  --placeholderFontSize: 12px;
}

textarea#exampleAddress::placeholder {
  font-size: 12px;
  /* line-height: 14px !important; */
  text-align: right;
  letter-spacing: 0.01em;
  color: #c4c4c4;
  /* overflow:hidden; */
  height: 3rem;
  resize: none;
  /* padding-top: 1.9rem !important; */
  transform: translateY(2rem);
}
.card {
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
}
@-ms-viewport {
  width: device-width !important;
}

@media all and (min-width: 90vw) {
  .SignUP-Card {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
  }

  .sub-heading {
    margin: 1rem;
    margin-left: 3px;
    /* margin-left: 11rem; */
    font-size: 1.125rem;
    color: #4f4f4f;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }
}

.Main1 .col-sm-3 {
  margin-bottom: 20px;
  padding-bottom: 30px;
}

@media (max-width: 767px) {
  button.SignUp-Submit.btn.btn-primary,
  button.SignUp-Back.btn.btn-outline-primary {
    width: 100%;
    margin-bottom: 15px;
  }
}

.form-text{
  margin: 1vh 0 0 0 !important;
}