.mt-16 {
  margin-top: 1.6vh !important;
}
.mb-16 {
  margin-bottom: 1.6vh !important;
}
.pt-16 {
  padding-top: 1.6vh !important;
}
.pb-16 {
  padding-bottom: 1.6vh !important;
}
.mt-8 {
  margin-top: 0.8vh !important;
}
.mb-8 {
  margin-bottom: 0.8vh !important;
}
.mb-24 {
  margin-bottom: 2.4vh !important;
}

/*___________For RFQ dyed1 screen________*/
.card {
  border: none !important;
}
div.RFQ-Card.card.card-body {
  width: 20.84vw;
  height: 10.2vh;
  background: #ffffff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px !important;
  padding: 1.6vh 1.12vw;
}
div.RFQ-Row.row {
  margin-top: 2vh;
}

div.RFQ-Row-weave {
  display: flex !important;
  justify-content: center !important;
  margin-top: 2vh;
}
.RFQ-Row {
  display: flex !important;
  justify-content: center !important;
  vertical-align: middle;
  margin-top: 14.7vh;
}
.RFQ-h4 {
  font-size: 2.4vh;
  line-height: 2.9vh;
  letter-spacing: 0.01em;
  color: #333333;
  text-align: center;
  margin-top: 10vh;
  margin-bottom: 0;
}
/***for rfq button***/
button.Rfq-button.btn.btn-secondary {
  font-weight: 700;
  font-size: 1.4vh;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  background: #2f80ed;
  border-radius: 16px;
  width: 6.95vw;
  height: 3.2vh;
  border: none;
  margin: 0;
  padding: 0;
}
.RFQ-Title {
  font-weight: 600;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  margin-bottom: 1.6vh;
}

/*__________for RFQ order_______________*/
div.RFQ-order-card.card.card-body {
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  width: 55vw;
  height: 23.9vh;
  margin: 2.8vh 0 0 11.88vw;
  padding: 3.6vh 1.12vw 2.6vh;
}
.margin-div {
  margin: 10vh 0 0 32.64vw;
  font-size: 2.4vh;
  line-height: 2.2vh;
  letter-spacing: -0.408px;
  color: #333333;
}

div.RFQ-order-cards.card.card-body {
  height: 239px !important;
  width: 698px;
  border-radius: 10px;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
/* margin-top: 4rem;
div.RFQ-Card.card.card-body {
  width: 18.75rem;
  height: 6.375rem !important;
  background: #ffffff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
div.RFQ-Row.row {
  display: flex !important;
  justify-content: center !important;
  margin-top: 5rem;
}

div.RFQ-Row-weave {
  display: flex !important;
  justify-content: center !important;
  margin-top: 2rem;
}
.RFQ-Row {
  display: flex !important;
  justify-content: center !important;
  vertical-align: middle;
}
.RFQ-h4 {
  text-align: center;
  font-size: 24px !important;
  line-height: 22px;
  text-align: center;
  margin-top: 8rem;
  letter-spacing: -0.408px;

  color: #333333;
}

/*__________for RFQ order_______________*/

div.RFQ-order-cards.card.card-body {
  height: 239px !important;
  width: 698px;
  border-radius: 10px;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* margin-top: 4rem;
margin-left: 6rem; */
}
.RFQ-Order {
  display: flex !important;
  justify-content: center !important;
  vertical-align: middle;
}
.RFQButtons {
  display: flex;
  float: right !important;
  flex-direction: row-reverse;
}
.order-name,
.existing-order {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  margin-bottom: 0.8vh;
}
.type-of-weave {
  font-size: 1.6vh !important;
  line-height: 1.9vh !important;
  letter-spacing: 0.01em;
  padding: 0 0.56vw !important;
  width: 46.25vw !important;
  height: 4.8vh !important;
}
.type-of-weave::placeholder {
  font-size: 1.6vh !important;
  line-height: 1.9vh !important;
  letter-spacing: 0.01em;
  color: #c4c4c4 !important;
}
button.RFQ-Back.btn.btn-secondary {
  font-weight: 500;
  font-size: 1.4vh;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  color: #2f80ed;
  border: 1px solid #2f80ed;
  width: 10.42vw;
  height: 3.2vh;
  background-color: #fff;
  padding: 0;
  margin: 0;
}
button.RFQ-Submit.btn.btn-secondary {
  font-weight: 700;
  font-size: 1.4vh;
  line-height: 1.6vh;
  letter-spacing: 0.02em;
  background: #2f80ed;
  width: 10.42vw;
  height: 3.2vh;
  padding: 0;
  border: none;
  margin: 0 0 0 0.56vw;
}
.width-values {
  color: #333333 !important;
}
input#exampleOrder.Rfq-Order-input.form-control {
  width: 22.57vw;
  height: 4.8vh !important;
  border: 0.5px solid #000000;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  padding: 0 0.56vw;
  margin-bottom: 1.6vh;
}
input#exampleOrder.Rfq-Order-input.form-control::placeholder {
  color: #c4c4c4;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
}
select#exampleSelectOrder.Rfq-Order-Select.form-control {
  height: 48px;
  width: 325px;
  border-radius: 4px;
}
Select#exampleSelectOrder.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml,<svg fill='blue' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: center right;
}

select#exampleSelectOrder1.Rfq-Order-Select.form-control {
  height: 48px;
  width: 325px;
  border-radius: 4px;
}
Select#exampleSelectOrder1.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml,<svg fill='blue' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: center right;
}
select#exampleSelectOrder4.Rfq-Order-Select.form-control {
  height: 48px;
  width: 325px;
  border-radius: 4px;
}
Select#exampleSelectOrder4.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml,<svg fill='blue' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right center;
  border: 0.5px solid #000000;
  width: 16.16vw !important;
  height: 4.8vh;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  padding: 0 0.56vw;
}
.text-capitalize {
  text-transform: capitalize !important;
}
Select#yarn-type.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml,<svg fill='blue' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right center;
  width: 16.15vw;
  height: 4.8vh;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  border: 0.5px solid #000000;
  padding: 0 0.56vw;
  color: #4f4f4f !important;
}

#exampleSelectOrder option {
  width: 100px !important;
  /* text-transform: lowercase;  */
}

.RFQ-order-select {
  color: white;
}
.RFQ-order-select:hover {
  color: white !important;
  text-decoration: none;
}

/*****OrderKate********/
select#exampleSelectOrder.Rfq-Order-Kate.form-control,
select#exampleSelectOrder1.Rfq-Order-Kate.form-control {
  border: 0.5px solid #000000;
  width: 22.57vw;
  height: 4.8vh;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  padding: 0 0.56vw;
}
select#exampleSelectOrder.Rfq-Order-Kate.form-control option:disabled,
select#exampleSelectOrder1.Rfq-Order-Kate.form-control option:disabled {
  color: #c4c4c4;
}

div.RFQ-orderkate-card.card.card-body {
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
  width: 55vw;
  padding: 3.6vh 1.12vw 2.6vh;
}

div.RFQ-order-kate {
  background: #ffffff;
  border-radius: 10px;
  /* width: 698px; */
  height: 358px;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* div.RFQ-order-kate1 {
  background: #ffffff;
  border-radius: 10px;
  width: 698px;
  padding: 1rem 0rem 0rem 0rem;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
} */
div.RFQ-order-kate1 {
  width: 55vw;
  padding: 3.6vh 0 0;
}
.input-saved {
  margin: 0 0 0 1.12vw;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #828282;
}
.input-saved-nomargin {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #828282;
}
.p-select {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #828282;
  margin-top: 1.1vh;
}

.inputs-saved {
  margin: 1.6vh 0 1.6vh 1.12vw;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #828282;
}
.input-weave {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  margin-bottom: 0.8vh;
}

input#exampleReed.form-control:after {
  content: "X";
}

textarea#exampleOrderText.form-control {
  width: 25.84vw;
  height: 8vh !important;
  border: 0.5px solid #000000;
  padding: 0.8vh 0.56vw !important;
  resize: none;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
}
textarea#exampleOrderText.form-control::placeholder {
  font-size: 0.84vw;
  line-height: 0.98vw;
  letter-spacing: 0.01em;
  color: #c4c4c4;
  right: 2px;
  bottom: 3px;
}

div.RFQ-order-weave-card.card.card-body {
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  width: 55vw;
  margin-top: 3.2vh;
  padding: 3.2vh 1.12vw;
}

/******For order Kate Weave new Screen****/
.AddColour {
  display: flex;
}

.AddColour input {
  height: 48px;
  width: 100px;
  margin: 1rem 1rem 0rem 0rem;
  border-radius: 4px;
  background: #ffffff;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  /* margin: 0px !important; */
}
.Add-Colour {
  display: flex;
  white-space: nowrap !important;
}
tr.dyed-color-final {
  width: 300px;
  color: #333333;
}
.dyed-color-final th,
.dyed-color-final td {
  flex: 1 1 100px;
  word-break: break-all;
  padding: 5px !important;
  width: 200px;
  align-items: center;
}

.print-dyed-heading {
  font-weight: 600;
  letter-spacing: 0.1em;
  color: #333333;
  padding: 0 0 10px 17px;
}
.print-dyed-heading > div {
  flex: 1 1 auto;
}

.form-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.dyed-head {
  padding-left: 91px;
}

button.add-clour {
  background-color: #ffffff;
  border: none !important;
  color: #2f80ed;
}
span.Order-Btn-text {
  margin-left: 0.56vw;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #2f80ed;
}
/***forwrap and weft output***/
.Wrap-Weft-Ot {
  display: flex;
}
div.warpoutput.row {
  padding: 0 0 1.67vw 2.78vw;
}
.ml-47 {
  margin-left: 3.27vw !important;
}
.ml-18 {
  margin-left: 1.25vw !important;
}
.pl-32 {
  padding-left: 2.23vw !important;
}
.ml-41 {
  margin-left: 2.8vw !important;
}

div.warpoutput1.row {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 2rem;
}

/****for RfqDyed13 screen***/

input#exampleRfqdyed13.form-control {
  width: 25.84vw;
  height: 4.8vh !important;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  border: 0.5px solid #000000;
  padding: 0.8vh 0.56vw;
}
input#exampleRfqdyed13.form-control::placeholder {
  color: #c4c4c4 !important;
}

div.expdelcol2.col-md-2 {
  margin-top: 0.6rem;
}
div.Upload-image-rfq {
  display: flex !important;
  word-spacing: 5px;
  align-items: center;
}
span.uppload-rfq-span {
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vh;
  margin-right: 0.2rem;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #4f4f4f !important;
  text-decoration-line: none !important;
}
span.upploadd-rfq-span {
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vh;
  margin-left: 0.2rem;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #4f4f4f !important;
  text-decoration-line: none !important;
}
textarea#exampleRfqdyed13.form-control {
  width: 325px;
  height: auto !important;
}

button.Rfq-Submit.btn.btn-secondary a {
  color: #ffffff !important;
}
.mb-14 {
  margin-bottom: 1.4vh !important;
}

hr.hr-order-kate {
  margin: 0 1.12vw !important;
}

div.FirstfieldRFQ {
  margin: 1rem 1rem 0rem 1rem;
}
div.FirstfieldRFQ p {
  font-weight: normal;
  font-size: 1.7vh;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #4f4f4f;
}

tr.rfqtr {
  display: flex;
  font-weight: normal;
  font-size: 1.5vh;
  line-height: 17px;
  text-decoration: underline !important;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #828282;
}

th.rfqdyedth {
  font-style: normal;
  font-weight: normal;
  font-size: 1.7vh;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #828282;
  text-decoration-line: none !important;
}

.select-process {
  display: flex;
}

.Edit-rfq {
  height: 20px;
  margin-left: 1rem;
  width: 20px;
}
.fields-add {
  display: flex;
  justify-content: space-between;
}

.flex-divs {
  display: flex;
  justify-content: flex-start;
}
.pre-ml-18 {
  margin-left: 1.8vw;
}

#exampleZip {
  width: 8.58vw;
  height: 4.8vh !important;
  /* margin-right: 1.12vw; */
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  border: 0.5px solid #000000;
  padding: 0 0.56vw;
}
#exampleZip::placeholder {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #c4c4c4;
}
.input-p {
  display: flex;
}
.paragraph {
  margin-left: 8px;
}
.spaced {
  margin: 10px;
  /* margin-right: 10px; */
}

.weave-section {
  height: 50px;
  width: 50px;
  border-radius: 30px;
}

input#editimage[type="file"]:before {
  height: 46px;
  font-size: 1.7vh;
  line-height: 32px;
  content: "Upload image" !important;
  display: inline-block;
  background: white;
  color: #2f80ed !important;
  text-decoration: underline !important;
  padding: 0 0px;
  border: none !important;
  text-align: center;
  /* position: absolute; */
  bottom: 8rem;
  width: 50px;
}

.picss input {
  border: none;
}

.edit-icon {
  margin: 0.15vh 1.29vw 0 0;
  width: 1.05vw;
  height: 1.5vh;
  cursor: pointer;
}
.warp-weft-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.input-saved i,
.input-weave i {
  margin-top: 1.6vh;
  display: block;
}
.warp-div {
  display: flex;
}
.warp-width {
  max-width: 26.95vw !important;
}
#yarn-type {
  /* margin-left: 15px; */
  height: 48px;
  width: 209px;
}
.reed {
  display: flex;
}
.Reed {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  margin-right: 7.96vw;
  margin-bottom: 0.8vh;
}
.X {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #4f4f4f;
  margin: 1.5vh 0.56vw 0;
}
.para-edit {
  display: flex;
  justify-content: space-between;
  padding: 1.6vh 0;
}
.stage-label {
  margin-top: 1.6vh;
}
.form-group {
  margin-bottom: 1.6vh !important;
  line-height: 0 !important;
}
.warp-weft-flex {
  display: flex;
}
.warp-p {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #828282;
  margin: 0.2vh 0 0.8vh;
}
.weft-p {
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #828282;
  margin: 0.2vh 0 0.8vh;
}
#date {
  width: 152px;
  /* margin-right: 15px; */
  height: 40px;
  font-size: 1.5vh;
}
.para-flex {
  display: flex;
}
.paragraph {
  margin: 2px;
}

.weave-section1 {
  height: 50px;
  width: 50px;
  border-radius: 30px;
  margin-top: 15px;
}

.bold-text {
  font-weight: 600;
  color: #333333;
  margin-left: 0.56vw;
}
.spacing-lr {
  margin-right: 15px;
}
.align-right {
  display: inline;
  margin-left: 0.56vw;
  font-weight: 600;
  color: #333333;
}
.optional-comments {
  resize: none;
  height: 80px !important;
}
.fabric-inline {
  display: inline-block;
}
.edit-icon-right {
  float: right;
}
.preferred-fabric {
  margin-bottom: 1.6vh;
  padding: 1.6vh 0;
  justify-content: space-between;
}

.order11 {
  display: flex;
}
.saved-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0px;
  height: 300;
  overflow: auto;
  align-items: center;
  font-size: 1.8vh;
  /* display: 'flex', */
  /* flex-direction: column-reverse; */
}
.RFQ-Row-saved {
  display: flex !important;
  justify-content: start !important;
  margin-top: 2rem;
}
.RFQ-Card-saved {
  height: 82px;
  width: 832px;
  margin-left: 25px !important;
  margin-top: 10px;
}
.inline-div-saved {
  display: flex;
  justify-content: space-around;
  margin-left: 0;
  width: 412px;
  font-size: 2vh;
  line-height: 2.5vh;
}
.inline-div {
  display: flex;
  justify-content: space-around;
  margin-left: -9rem;
  width: 412px;
}
.pdf-show-mar {
  margin-left: 10px;
}

.date-saved {
  margin-left: -4rem;
}
.saved-card.card-body {
  margin: 1.5vh;
  padding: 1.5vh !important;
  border-radius: 4px !important;
}
.data-rows-pd {
  display: flex;
  justify-content: space-around;
}
.inline-div-saved p {
  margin-bottom: 0 !important;
}
.mlr-3 {
  margin: 0 0 0 12px !important;
}
.ml-neg {
  margin: 0 0 0 -12px !important;
}
.delete-iconn-13 {
  width: 1.1vw;
  height: 1.1vw;
  margin-left: 0rem;
  cursor: pointer;
  margin-top: -9rem;
}

.delete-rfq {
  width: 1.05vw;
  height: 1.05vw;
  cursor: pointer;
  margin: 2vh 0 0 1vw;
}
.button-align {
  display: flex;
  justify-content: space-between;
  width: 200px;
}
.created-data {
  width: 347px;
}
.BusinessNext-btn {
  height: 35px !important;
  padding: 0.275rem 0.75rem !important;
}
.RFQ-Buttons {
  padding: 3.2vh 1.12vw;
}
.text-capitalizes {
  text-transform: capitalize;
}
.col-828282 {
  color: #828282 !important;
}
.ml-59 {
  margin-left: 4.09vw !important;
}

.lato-14 {
  font-family: "Lato", sans-serif;
  font-size: 1.5vh;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #828282;
  text-decoration: underline;
}
.w-214 {
  width: 214px !important;
}
.mt-10 {
  margin-top: 1vh !important;
}
/* .image-uploader {
  font-weight: 500;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #2f80ed;
  margin-right: 4px;
  margin-bottom: 0.8vh;
} */
.image-uploader {
  font-weight: 500;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #2f80ed;
  margin-right: 4px;
  margin-bottom: 0vh;
}
.fc-print-name {
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #000000;
}
.font-82 {
  font-weight: 400;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #828282;
  margin-right: 0.56vw;
}
.w-100px {
  width: 6.95vw !important;
}
.input-100px {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  width: 7vw !important;
  height: 4.8vh !important;
  padding: 0 0 0 0.5vw;
}
.input-50px {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  width: 5vw !important;
  height: 3.2vh !important;
  padding: 0 0 0 0.5vw;
}
.input-100px::placeholder {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #c4c4c4;
}

.new-color-input::placeholder {
  position: absolute;
  top: 0.2vh;
}
/* ::placeholder{
  line-height: inherit !important;
} */
.upload-print-design {
  font-weight: 500;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #2f80ed;
  text-decoration: underline;
  margin-bottom: 0.8vh;
}
.max-size {
  color: #dc3545;
  text-decoration: none;
  display: block;
  margin-bottom: 0;
  margin-top: 0.8vh;
}

.customization-div {
  width: 24.31vw !important;
  height: auto;
  background: #f2f2f2;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;
  padding: 1.8vh 1.53vw 1.6vh 0.56vw;
  margin-bottom: 1.6vh;
  margin-top: 0.4vh;
}
.customization-form {
  display: flex;
  width: 100%;
  margin-bottom: 1vh;
}
.mr-10 {
  margin-right: 0.7vw;
}
.app-left {
  width: 21.12vw;
}
.app-right {
  width: 24.31vw;
  margin-left: 1.88vw;
}
.custom-left {
  width: 21.12vw !important;
  margin: 1.6vh 0 0 -0.56vw;
}
/* .custom-left .customization-form {
  padding: 18px 0.56vw 16px !important;
} */
.custom-left .customization-form .validate .input-100px {
  width: 6.95vw !important;
  margin-right: 0.7vw;
}
.custom-left .customization-form .picss .btn-primary {
  margin: 0.8vh 0 0 !important;
}
.custom-right {
  margin: 1.6vh 0 0;
}
/* .custom-right .customization-form {
  padding: 18px 0.56vw 10px !important;
} */
.custom-right .customization-form .validate .input-100px {
  width: 6.95vw !important;
  margin-right: 0.7vw;
}
.custom-right .customization-form .btn-primary {
  margin: 1vh 0 -1vh !important;
}
.w-350 {
  width: 24.31vw;
  margin-left: 1.88vw !important;
}
.Add-button-custom {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.4vh !important;
  line-height: 1.6vh !important;
  letter-spacing: 0.02em;
  background: #2f80ed;
  border-radius: 5px !important;
  padding: 0 !important;
  width: 3.58vw;
  height: 3.2vh;
}
.placeholder {
  bottom: -4px;
  right: -85px;
  font-size: 1.1vh;
  color: #ccc;
  font-family: arial;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
  -webkit-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;
}
input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  white-space: pre-line;
  /* white-space: nowrap; */
  font-size: 1.6vh;
}
textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  /* white-space: pre-line; */
  position: absolute;
  bottom: 0;
  right: 5px;
  font-size: 1.1vh;
}
.close-x-custom {
  position: absolute;
  top: 9.33px;
  right: 5.33px;
  width: 9.33px;
  height: 9.33px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.cross {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
  width: 9.33px;
  height: 2vh;
  margin-left: 11.33px;
}

/* .dyed-color-custom-head td,
.dyed-color-custom-head th {
  flex: 1 1 auto;
  width: 200px;
  padding: 5px;
} */
.dyed-color-custom-head {
  font-weight: 400;
  font-size: 1.4vh;
  line-height: 1.7vh;
  letter-spacing: 0.01em;
  color: #828282;
  padding: 0;
}
.text-underline {
  text-decoration: underline !important;
}
.dyed-color-custom-head th div {
  font-weight: 400;
  font-size: 1.4vh;
  line-height: 1.7vh;
  letter-spacing: 0.01em;
  color: #828282;
  text-decoration: underline;
}
.dyed-color-custom-head th {
  padding: 0;
}
.dyed-color-custom-head p,
.dyed-color-custom-head div {
  overflow: hidden;
}
.imageBorder {
  display: inline-block;
}
.order-pdf {
  font-size: 1.6vh;
}
.colour-text {
  font-weight: 500;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #333333;
  padding: 0 !important;
}
.roman-no {
  padding: 0 !important;
  width: 2.02vw !important;
  font-size: 1.6vh;
  line-height: 1.9vh;
  letter-spacing: 0.01em;
  color: #828282;
  margin-bottom: 0.8vh;
  display: inline-block;
}
.color-box {
  padding: 0 !important;
  width: 5.21vw !important;
  margin-bottom: 0.8vh;
  display: inline-block;
}
.color-code {
  padding: 0 !important;
  width: 5.21vw !important;
  margin-bottom: 0.8vh;
  text-align: center;
  display: inline-block;
}
.max-qty {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.qty-boxx {
  padding: 0 !important;
  width: 5.33vw !important;
  margin-bottom: 0.8vh;
  /* margin-left: 2.3vw; */
  display: inline-block;
  text-align: center;
}

.qty-boxx-new {
  padding: 0 !important;
  width: 5.33vw !important;
  margin-bottom: 0.8vh;
  display: inline-block;
  text-align: center;
}
.qty-boxx-new1 {
  margin-left: -1vw !important;
  padding: 0 !important;
  width: 5.33vw !important;
  margin-bottom: 0.8vh;
  display: inline-block;
  text-align: center;
}
.w-60 {
  width: 4.17vw !important;
}

input#editprintimage[type="file"]:before {
  height: 46px;
  font-size: 1.7vh;
  line-height: 32px;
  content: "Upload print design" !important;
  display: inline-block;
  /* background: white; */
  color: #2f80ed !important;
  text-decoration: underline !important;
  padding: 0 0px;
  border: none !important;
  text-align: center;
  /* position: absolute; */
  bottom: 8rem;
  width: 50px;
}
.newOrder {
  font-weight: 500 !important;
  font-size: 1.4vh !important;
  line-height: 1.6vh !important;
  letter-spacing: 0.02em;
  color: #2f80ed !important;
  border: 1px solid #2f80ed !important;
  border-radius: 4px !important;
  width: 10.42vw;
  height: 3.2vh;
  background-color: transparent !important;
  position: absolute;
  right: 2.23vw;
}
.ff {
  width: 118px !important;
}
.fff {
  width: 200px !important;
}
.width-div {
  display: flex;
  justify-content: space-around;
}
.ffff {
  width: 200px !important;
  margin-left: -120px;
}
.fff2 {
  margin-left: -50px;
  width: 200px;
}
.small {
  font-size: 8px;
}

.blank-box {
  width: 1.46vw;
  line-height: 19px;
}
.print-box {
  width: 5.42vw;
  margin-right: 1.74vw;
  line-height: 19px;
}
.qty-box {
  width: 4.45vw;
  margin-right: 1.6vw;
  line-height: 19px;
}
.design-box {
  width: 5.52vw;
  line-height: 19px;
}
.colour-box {
  width: 3.03vw;
  margin-right: 3.02vw;
  line-height: 19px;
}
.code-box {
  width: 5.49vw;
  margin-right: 3.05vw;
  line-height: 19px;
}
.qtty-box {
  width: 4.01vw;
  line-height: 19px;
}

/* React date picker custom style */

.react-datepicker__header {
  background: #5cb85c !important;
  border-bottom: unset !important;
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
}

.react-datepicker__day-names {
  background-color: #fff !important;
}
.react-datepicker__current-month {
  padding: 10px !important;
  font-size: 0.6rem !important;
  color: #ffffff !important;
  margin-bottom: 0.3rem;
}
.react-datepicker__navigation {
  /* align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 17px !important;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden; */
  top: -0.65rem !important;
}
.react-datepicker__triangle {
  display: none !important;
}
.react-datepicker-popper {
  padding: 10px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0 0 10px 0 #aaa;
  margin-top: 10px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  /* color: #54708b !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; */
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #5db85c !important;
  color: #fff !important;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #e0e0e0 !important;
  border-radius: 0.2rem !important;
  display: inline-block;
  position: relative;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff !important;
}

#print-design th,
#print-design td {
  flex: 1 1 auto;
  width: 200px;
  padding: 5px;
  word-break: break-all;
}

.print-tr-decor th {
  color: #828282;
  font-size: 1.5vh;
  font-weight: 400;
  text-decoration: underline;
}

.print-td-decor td {
  color: #333333;
}

.print-dyed-title .warp-p,
.print-dyed-title .weft-p {
  font-weight: 600;
  color: #333333;
  margin: 1vh 0 1.6vh;
}
.sus-select {
  max-width: 10vw;
}
.underline {
  text-decoration: underline !important;
}
.cust-link {
  font-size: 1.6vh;
  font-weight: 500;
  margin-bottom: 1.6vh;
  cursor: pointer;
}
.file-upload-modal .modal-dialog {
  max-width: 30vw;
}
.file-upload-modal-content {
  background-color: #f2f2f2;
  height: 55vh;
  width: 30vw;
}
.file-upload-modal-content .file-header {
  color: #828282;
  font-size: 2vh;
  text-align: center;
  width: 100%;
}
.drop-zone {
  width: 100%;
  text-align: center;
  border: 1px dashed black;
  color: #333333;
  margin-top: 6vh;
  font-size: 1.6vh;
  display: flex;
  height: 12vh;
  justify-content: center;
  align-items: center;
}
.drop-zone a {
  color: #2f80ed !important;
  cursor: pointer;
}
.drop-zone input {
  visibility: hidden;
  width: 1px;
}
.upload-button {
  display: flex;
  justify-content: space-around;
  padding: 3vh;
  margin: 2vh;
  font-size: 1.6vh;
}
.upload-Submit {
  width: 7.5vw;
  height: 3.2vh;
  line-height: 1;
  background-color: #2f80ed;
  color: #ffffff;
  border: none;
  border-radius: 5px;
}
.upload-Back {
  width: 7.5vw;
  height: 3.2vh;
  line-height: 1;
  background-color: transparent;
  border: 1px solid #2f80ed;
  color: #2f80ed;
  border-radius: 5px;
}
.file-zone {
  height: 15vh;
  font-size: 1.6vh;
  line-height: 2vh;
  margin-top:5vh
}
.file-zone .file-serial {
  color: #828282;
  text-decoration: underline;
  margin-right: 0.5vw;
}
.file-zone .file-cross {
  margin-left: 0.5vw;
  color: #4f4f4f;
  cursor: pointer;
}
.file-zone .file-cross:hover{
  text-decoration: none !important;
  color: #333333;
}
.file-zone .file-name {
  margin-bottom: 0.5vh !important;
}
.upload-link {
  color: #2f80ed;
  cursor: pointer;
}
.image-table {
  border: 1px solid #b9b9b9;
  border-collapse: collapse;
  line-height: initial;
  margin: 1.6vh 0;
  width: 35vw;
  max-width: 45vw;
}
.cust-table {
  border: 1px solid #b9b9b9;
  width: 25vw;
  border-collapse: collapse;
  line-height: initial;
  max-width: 25vw;
}
.view-table.cust-table{
  width:20vw;
}
.cust-table th,
.image-table th {
  background-color: rgba(0, 0, 0, 0.06);
  font-size: 1.6vh;
  padding: 1vh;
  border: 1px solid #b9b9b9;
  font-weight: 600;
}
.hide-border {
  border: hidden !important;
  border-left: 1px solid #b9b9b9 !important;
  background-color: #fff !important;
}
.cust-table td,
.image-table td {
  color: #000000;
  font-size: 1.6vh;
  padding: 1vh;
  border: 1px solid #b9b9b9;
}
.image-table a,
.cust-table a {
  color: #2f80ed !important;
  cursor: pointer;
  display: block;
  text-decoration: underline !important;
}

.image-table td button {
  background-color: transparent;
  border: none;
  display: block;
  font-size: 1.6vh;
  color: #2f80ed;
}
