.search-icon {
  height: 20px;
  width: 20px;
  margin-left: -34px;
}

.search-fi {
  height: 32px !important;
  width: 200px !important;
  border-radius: 10px !important;
  background: rgba(255, 255, 255, 0.5) !important;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.2) !important;
  margin-right: 10px;
}
input.search-fi {
  border-color: none !important;
  border: none !important;
  text-align: right !important;
  padding-right: 40px !important;
}
.search-fi:hover {
  text-decoration: none !important;
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: #ffffff !important;
}
.toggler {
  width: 1.41vw;
  height: 1.41vw;
}
.dropdown-toggle::after {
  display: none !important;
}

.Header {
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  position: fixed !important;
  z-index: 9999;
  height: 5vh;
  padding: 0 !important;
}
.Header .logo {
  width: 8.89%;
  display: flex;
  margin-left: 1.43vw;
  justify-content: center;
  align-items: center;
}
.Header .right-header {
  width: calc(100% - 10.89%);
    padding: 0 1.07vw 0 4vw;
    height: 5vh;
}

.Header .has-search,
.Header .form-group {
  width: 13.89vw;
  height: 3.2vh;
  position: relative;
  margin: 0 !important;
}
.has-search .form-control-feedback {
  position: absolute;
  color: #333333;
  top: 0.5vh;
  right: 0.56vw;
}
.has-search .form-control {
  font-family: "Roboto", sans-serif;
  font-size: 1.6vh !important;
  line-height: 1.9vh !important;
  color: #333333 !important;
  text-align: end;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
  width: 100%;
  height: 100% !important;
  border: none !important;
  padding: 0 2.7vw 0 0 !important;
}
.has-search .form-control::placeholder {
  font-family: "Roboto", sans-serif !important;
  font-size: 1.6vh !important;
  line-height: 1.9vh !important;
  color: #333333 !important;
}
.notification-icon {
  margin: 0 1.25vw;
  font-size: 1.5vw;
}

.mr-30{
  margin-right: 2.07vw;
}



.HeaderMain {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2) !important;
  background-color: white;
  width: calc(100% + 30px) !important;
  position: fixed !important;
  z-index: 111 !important;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ffffff !important;
}
li.top-navigation-li.list-group-item {
  margin-right: 3.5rem !important;
}
li.top-navigation-li-cp.list-group-item {
  margin-right: 1rem !important;
}
ul.top-nav-yarn.list-group li {
  margin-right: 3.4rem !important;
}
a.Topnavigation-a {
  color: black;
}

li.list-group-item a {
  color: black;
  text-decoration: none;
}
ul.top-nav.list-group {
  display: flex;
  flex-direction: row !important;
  padding-left: 0;
  margin-right: 0rem;
  margin-bottom: 7px;
  border-radius: 0.25rem;
  text-align: left !important;
}
.top-nav-rfq .list-group-item {
  font-size: 2.2vh;
  background-color: transparent;
  padding: 0 3.58vw !important;
}
.top-nav-rfq .list-group-item a {
  color: #333333;
}
.top-nav-rfq .list-group-item:hover,
.top-nav-rfq .activated a {
  color: #2f80ed;
  text-decoration: none;
}

ul.top-nav-yarn.list-group {
  display: flex;
  flex-direction: row !important;
  padding-left: 0;
  padding-right: 7rem;
  margin-bottom: 8px;
  border-radius: 0.25rem;
  text-align: left !important;
  height: inherit;
}
ul.top-nav-rfq.list-group {
  flex-direction: row !important;
}

.navbar-brand {
  font-family: "Saira", sans-serif !important;
}
.navbar-light .navbar-brand {
  font-family: "Saira", sans-serif !important;
  font-weight: 500;
  font-size: 3vh;
  line-height: 3.8vh;
  color: #000000 !important;
  margin-right: 0;
  padding: 0;
}
.navbar-brand {
  display: inline-block;
  padding-bottom: 1.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: 38px;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .HeaderMain {
    width: calc(100% + 30px) !important;
    top: 0;
  }
  .navbar-light .navbar-brand {
    padding-left: 10px !important;
    font-size: 22px;
  }
}

.Bell-icon {
  margin-left: 33px;
}
.logout {
  font-weight: 600 !important;
  font-size:2vh ;
  padding:1.5vh;
  margin-top: 4px;
  margin-bottom: 4px;
  font-family: 'lato';
text-decoration: none; 
}
.logout:hover{
  text-decoration: none !important;
}
.dropdown-menu,.dropdown-item {
  padding: 0 !important;
}
.dropdown-item {
  padding: 4px 2px 2px 2px !important;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: transparent !important;
}
.dropdown-menu.show {
  top: 23px;
  right: -22px;
  min-width: 108px;
  min-height: 31px;
}

.FormHeader {
  z-index: 0;
}

.list-group-item:last-child {
  padding-right: 0 !important;
  margin-right: 0;
}
.Header .navigation .list-group {
  margin: 0;
  height: inherit;
}
.Header .nav-item {
  margin: 0;
}
.Header .nav-item .dropdown-toggle {
  padding: 0;
}

.OpenEnquiries-main {
  margin: 0 !important;
}
.fabtrackContent .col-sm-2 {
  max-width: 200px !important;
  padding: 0;
}
.fabtrackContent .col-sm-10 {
  max-width: calc(100% - 200px) !important;
  flex: none;
}
.fabtrackContent .leftSideBar .card {
  max-width: 200px !important;
  margin-top: 50px !important;
}
.fabtrackContent .leftSideBar .card .Profile-overlay {
  padding: 0 36px 0 0;
}
.fabtrackContent .leftSideBar .card .card-body {
  padding: 8px !important;
}
.fabtrackContent .leftSideBar .card .image-border {
  border: none;
}
.fabtrackContent .leftSideBar .card .image-border img {
  border: none;
  margin-bottom: 8px;
}
.fabtrackContent .leftSideBar .card .sideBarHeading {
  font-size: 16px;
  line-height: 19px;
}
.fabtrackContent .leftSideBar .card .sideBarText-top {
  line-height: 14px;
}
.fabtrackContent .leftSideBar .card .sideBarText-btm {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #696969;
  margin-top: 3px;
}
.fabtrackContent .leftSideBar .card .profile-hr {
  margin-bottom: 0;
  border-top: 1px solid rgba(105, 105, 105, 0.32);
}
.fabtrackContent .leftSideBar .card .list-group-flush {
  margin-top: 65px;
}
.fabtrackContent .leftSideBar .card .list-group-item {
  padding: 0 !important;
  margin: 0 0 19px 16px;
}
.fabtrackContent .leftSideBar .card .list-group-item a {
  font-weight: 400;
  font-size: 18px !important;
  line-height: 21px !important;
}
.fabtrackContent .leftSideBar .card .list-group-item a:hover {
  text-decoration: none;
  color: #2f80ed !important;
  font-weight: 700;
}
.fabtrackContent .leftSideBar .card .list-group .active a {
  font-weight: 700;
  color: #2f80ed !important;
}

.fabtrackContent .rightSideBar .nav-tabs {
  margin: 50px 0 0 0 !important;
}
.fabtrackContent .rightSideBar .nav-tabs .nav-item .nav-link {
  font-family: Roboto;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.5);
  padding: 7px 20px 4px;
  margin-left: 10px;
  margin-right: 17px;
}
.fabtrackContent .rightSideBar .nav-tabs .nav-item .nav-link:hover {
  border: none;
  color: #000000 !important;
  border-bottom: 2px solid #000000;
}
.fabtrackContent .rightSideBar .nav-tabs .active {
  color: #000000 !important;
  border-bottom: 2px solid #000000 !important;
}
.fabtrackContent .rightSideBar .openEnquiries-labdip {
  margin: 114px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .fabtrackContent .col-sm-2 {
    max-width: 180px !important;
  }
  .fabtrackContent .col-sm-10 {
    max-width: calc(100% - 180px) !important;
  }
  .fabtrackContent .leftSideBar .card .list-group-item a {
    font-size: 16px !important;
    line-height: 19px !important;
  }
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.notification{
  margin: 0 !important;
  padding: 0 !important;
}


.not-container{
  position: fixed;
  top:5%;
  right: 4%;
  background-color: #ffffff;
  height: 85vh;
  width: 30vw;
  min-width: 30vw;
  min-height: 85vh;
  padding:0;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
  }
  
  .not-header{
  text-align: center;
  color:#333333;
  font-weight: 600;
  font-size: 2.2vh;
  font-family: 'lato';
  padding:1vh 0;
  }
  .brd-btm{
  border-bottom: 1px solid rgba(0,0,0,0.3);
  }
  .single-not{
  border-bottom: 1px solid rgba(0,0,0,0.3);
  padding:1.5vh;
  margin:0.5vh
  }
  .single-not a{
  font-family: 'lato';
  font-size: 1.8vh;
  line-height: 2.1vh;
  color: #4F4F4F !important;
  font-weight: 500;
  }
  .blue-dot{
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #2f80ed;
  
  
  }
  .cross-icon{
  float: right;
  font-size: 1.6vh;
  }
  .v-center{
  margin:auto 0;
  }

  .notificaiton-sec{
    position: relative;
    margin:0 2.13vw;
    cursor: pointer;
  }
  .not-num{
    position: absolute;
    top:-3px;
    right: -14px;
    border-radius: 50%;
    font-size: 1.2vh;
    line-height: 14px;
    color: #ffffff;
    font-weight: 800;
  text-align: center;
    width: 19px;
    height: 16px;
    background-color:#EB5757;
  }

  .single-not1{
    position:fixed;
    z-index: 1000;
    top:0;
    right:5px;
    height:60px;
    max-width: 25vw;
    background-color: #FFF7EA;
    border-radius: 10px;
    align-items: center;
    border: 1px solid orange;
    }
    .single-not1 {
    padding:1.5vh;
    margin:0.5vh
    }
    .single-not1 a{
    font-family: 'lato';
    font-size: 1.8vh;
    line-height: 2.1vh;
    color: #4F4F4F !important;
    font-weight: 500;
    }
    .v-center1{
    margin:auto 0;
    }

    .not-message{
      text-align: left;
      margin-left:5px;
      width: 27vw;
      }
    .not-message1{
    text-align: left;
    margin-left:5px;
    width: 27vw;
    }

    .notif-bold a{
      font-weight: 800;
      }
    .notif-bold1 a{
    font-weight: 800;
    }
    .not-num {
      top: -6px!important;
      right: -17px!important;
      font-size: 9px!important;
      line-height: 19px!important;
      width: 19px!important;
      height: 19px!important;
  }