
.badge-number{
    background-color: #EB5757 !important;
    border-radius: 49px !important;
}

button.typereject-btn.btn.btn-danger{
    background: #EB5757;
border-radius: 4px;
width:5vw;
height:3.5vh;
color: #ffffff;
text-align: center;
padding: 0px !important;
}

button.typeblock-btn.btn.btn-warning{
background: #F2994A;
border-radius: 4px;
width:5vw;
height:3.5vh;
color: #ffffff;
text-align: center;
padding: 0px !important;
}

button.typeUnblock-btn.btn.btn-success{
background: #27AE60;
border-radius: 4px;
width:5vw;
height:3.5vh;
color: #ffffff;
text-align: center;
padding: 0px !important;
}

button.typeaccept-btn.btn.btn-primary{
    border-radius: 4px;
    width:5vw;
    height:3.5vh;
    color: #ffffff;
    padding: 0px !important;
    text-align: center;
    background: #2F80ED;
}

a.View-Linkreq{
    text-decoration: underline !important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: white !important;
    opacity: 1;
}

/* .table-heading-row{
    font-size: 14px;
} */