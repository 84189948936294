h1, h2, h3, h4, h5, h6, p{
    margin-bottom: 0;
}
a{
    text-decoration: none;
}

.margin-l21{
    margin-left: 21px;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    
}

/* Order */
.order{
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 18px 32px 16px;
    padding: 16px;
    /* margin-top: 7rem; */
}

.text-16,
.responses-purple,
.unseen-red,
.seen-green{
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #333333;
    margin-bottom: 16px;
}
.text-14,
.view-more,
.quantity,
.submission-date,
.responses{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #828282;
    margin-bottom: 12px;
}
.text-14 span{
    font-weight: 500;
    color: #4F4F4F;
    margin-left: 8px;
}
.weave-oxford{
    margin-bottom: 46px;
}
.colour-blue{
    margin-bottom: 5px;
}
.colour-blue span{
    margin-left: 11px;
}
.view-more{
    font-weight: 500;
    color: #2F80ED;
    text-decoration: underline;
    margin-right: 12px;
}
.warp{
    margin-top: 35px;
}
.construction{
    margin: 0 32px;
}
.dimensions{
    margin-top: 21px;
    justify-content: space-between;
}
.dimensions .text-14{
    text-decoration: underline;
    margin-bottom: 8px;
}
.quantity{
    font-weight: 500;
    color: #4F4F4F;
    margin-bottom: 5px;
}
.quantity span{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
    margin: 0 6px;
}
.target-price{
    margin: 0 75px;
}
.width{
    margin-left: 45px;
}
.submission-date{
    color: #4F4F4F;
    margin-bottom: 15px;
    margin-right: 4px;
}
.submission-date .text-16{
    color: #4F4F4F;
    margin: 0 9px;
}
.submission-date .delete-button img{
    margin-top: -4px;
}
.responses{
    margin-bottom: 8px;
}
.responses-purple,
.unseen-red,
.seen-green{
    font-weight: 600;
    color: #9B51E0;
    margin-left: 8px;
    margin-right: 16px;
}
.unseen-red{
    color: #EB5757;
}
.seen-green{
    color: #27AE60;
}
.view-button{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    background-color: #2F80ED;
    border-radius: 4px;
    padding: 8px 22px;
    display: inline-block;
    margin-top: 102px;
}
.view-button:hover{
    color: #FFFFFF;
}
.Seller{
    /* position: absolute;
    bottom: 31px;
    right: 79px; */
    margin-bottom: 80px;

}

/* / Dropdown / */
.dropdown1{
    margin-left: 32px;
    margin-top: 7rem;
}
.dropdown-show,
.dropdown .btn-secondary{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #4F4F4F;
}
.dropdown1 .btn-secondary{
    background-color: transparent;
    border: none;
    color: #109CF1;
    padding: 0;
    margin-left: 4px;
    margin-top: -2px;
}
.dropdown1 .btn-secondary img{
    margin-left: 8px;
}
.dropdown1 .btn-secondary:focus{
    box-shadow: none;
}
.dropdown1 .dropdown-toggle::after {
    display: none;
}