html,
body {
  max-width: 100% !important;
  overflow-x: hidden !important;
}
.Sidebar-Admin > .card {
  margin-top: 5vh;
  width: 13.89vw;
  position: relative;
  border-radius: 0 !important;
  height: calc(100vh - 5vh);
}

.lactiveClass {
  color: blue !important;
}
/****/
.card-img-top {
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.1));
  /* height: 100px;
width: 100px; */
}
.Card-Sidebar .Profile-card-text {
  font-family: "Roboto", sans-serif;
  font-size: 1.2vh !important;
  line-height: 1.4vh;
  color: #828282;
}
.buyer-head{
  font-weight: 300;
  font-size: 1.4vh;
  line-height: 1.6vh;
  color: #696969;
  margin-top: 0.2vh;
  margin-bottom: 0;
}
.slightlyt-bigtest {
  font-size: 14px !important;
}

.Profile-span {
  margin-bottom: 1rem !important;
}

div.card-title.h5 {
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.1rem;
  font-size: 1.6vh;
  line-height: 1.9vh;
  color: #4f4f4f;
  margin-bottom: 0;
}
.Profile-Link {
  color: black !important;
  word-wrap: break-word !important;
}
/* .Profile-Link:hover{
    color: #007bff !important;
} */
.navigation{
  height: inherit;
}
.card.title {
  text-align: center !important;
}
div.list-group-flush.list-group {
  position: absolute;
  top: 31.5vh;
  width: 100%;
}
div.Card-Sidebar.card-body {
  padding: 1.6vh 0.56vw;
}

.top-nav .list-group-item{
  background-color: transparent;
  padding: 0;
  margin-right: 2.35vw;
  height: inherit;
}
.top-nav .list-group-item a{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 2.1vh;
  line-height: 2.6vh;
  color: #333333;
  padding: 1.2vh 2.9vw 0vh;
  display: block;
  height: inherit;
}
.top-nav .list-group-item a:hover,
.top-nav .activated a,
.top-nav .active a{
  color: #2F80ED;
  border-bottom: 2px solid #2F80ED;
}
.list-group-item{
  border: none !important;
}

.list-group-flush .list-group-item {
  padding: 0 0 1.9vh 1.12vw !important;
  line-height: 1.8vh;
}
.list-group-flush .list-group-item a {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 2.1vh !important;
  line-height: 2.1vh !important;
  color: #4f4f4f !important;
}
.list-group-flush .list-group-item a:hover,
.list-group-flush .active a {
  font-weight: 700;
  color: #2f80ed !important;
  text-decoration: none;
}

.image-border {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.6vh;
}

.image-icon {
  height: 23px;
  position: absolute;
  right: 29px;
  /* bottom: -65px; */
  margin-top: 39px;
  /* z-index: 1111111111111111111111111; */
  border: 1px solid black;
  border-radius: 22px;
  background: white;
  color: black;
  cursor: pointer;
}
.image-border > img {
  width: 6.95vw;
  height: 6.95vw;
  border-radius: 50%;
}
.card_ {
  width: 100% !important;
}

/***Top side nav***/
a.side_nav_item {
  color: black !important;
}
a.side_nav_item.active_item {
  color: blue !important;
}

div.list-group-item a {
  color: black !important;
  background-color: none !important;
  font-size: 16px !important;
  line-height: 21px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}
div.deactivate.list-group-item {
  color: black !important;
}
/* div.list-group-item a{
    color: blue !important;
} */

a:hover {
  color: #007bff !important;
}
.list-group-item.active {
  z-index: 2;
  color: none !important;
  background-color: transparent !important;
  border-color: none !important;
}

/*****for profilel button overlay***/
button.Profile-overlay {
  position: absolute;
  right: 2.5vw;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 2.6vh;
  margin-top: -0.6vh;
}

.profile-hr {
  margin: 1.6vh 0;
  border-top: 1px solid rgba(105, 105, 105, 0.32);
}
