div.GalleryPreviewRow.row {
  margin: 5vh 0 0;
}

div.Summaryprerow.row {
  margin-top: 5vh;
}

div.GalleryPreview.container {
  height: 63vh;
  overflow-y: scroll;
}

div.SumarryPre.container {
  background-color: #ffffff;
  width: 100%;
  height: 63vh;
  overflow-y: scroll;
}

div.BusinessPre {
  /* display: flex; */
  padding-left: 50px;
  height: 63vh;
  overflow-y: scroll;
}
.ComapnyPreviewTabpane .container {
  max-width: 100%;
  padding-left: 2.22vw;
  padding-right: 1vw;
  border-radius: 0;
}
div.AddressPre {
  padding-left: 42px;
  height: 63vh;
  overflow-y: scroll;
}
div.GalleryPreview.container img {
  width: 13.89vw;
  height: 13.89vw;
  object-fit: contain;
  border-radius: 4px;
}
div.PreviewCompany {
  width: 86.12vw;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 85.4vh;
  overflow: auto;
  padding-bottom: 5vh;
  position: relative;
  overflow-y: hidden;
}

ul.ComapnyPreviewtabs.nav.nav-tabs {
  background-color: #f2f2f2;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  position: unset;
}

li.ComapnyPreviewitems.nav-item a {
  font-weight: 600;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: -0.408px;
  color: #4f4f4f;
  width: 12.16vw;
  border: none;
  padding: 1.2vh 0;
}
li.ComapnyPreviewitems.nav-item a:hover {
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  color: #2f80ed;
}

li.ComapnyPreviewitems.nav-item a.active.nav-link {
  font-weight: 600;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: -0.408px;
  color: #2f80ed;
  background: #ffffff !important;
  border-radius: 10px 10px 0px 0px;
  width: 12.16vw;
  border: none;
  padding: 1.2vh 0;
  /* border-bottom: none !important;
  background-color: white !important;
  font-style: normal;
  text-align: center;
  padding: 11px 45px 12px 44px;
  border-radius: 10px 10px 0px 0px; */
}
div.tabsimage img {
    height:6.95vw;
    width:6.95vw;
    object-fit: contain;
    border-radius: 50%;
    box-shadow: .0 .5vh .5vh #ccc;
}

div.tabsimage {
  display: flex;
  /* margin-top: 3.5rem; */
  /* margin-left: 2rem; */
  background: #f2f2f2;
  padding: 18px 40px;
}
div.tabsaddress {
  margin-left: 1rem;
  margin-top: 1.5rem;
  word-wrap: break-word !important;
  overflow: hidden !important;
}
.mar-9{
  margin-top:8px
}
.mar-8{
  margin-bottom: 8px;
}
div.tabsaddress p {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
}

li.CompanyPreviewitems.nav-item {
  margin-left: 0rem !important;
}
div.tabsaddress span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #828282;
}

div.SumaryhePreview.col , div.SumaryhePreview.col-md-4{
  font-family: 'lato','sans-serif';
  font-size: 1.8vh;
  font-weight: 500;
  line-height: 2.2vh;
  letter-spacing: -0.408px;
  color: #4f4f4f;
}
div.Buisrowpre.row {
  font-weight: 500;
  font-size: 1.8vh;
  line-height: 2.2vh;
  letter-spacing: -0.408px;
  color: #4f4f4f;
}
div.SumaryhePreview.col p ,div.SumaryhePreview.col-md-4 p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.6vh;
  line-height: 2.4vh;
  color: #828282;
  margin: 0.8vh 0 2.4vh;
  text-align: justify;
}
.w-65 {
  width: 65vw;
}

div.Buisrowpre.row p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.6vh;
  line-height: 2.4vh;
  text-align: justify;
  color: #828282;
  margin: 0.8vh 0 2.4vh;
}

a.websiteemailpe p {
  color: #2f80ed !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  letter-spacing: -0.408px;
  text-decoration-line: underline;
}

/***for business**/

div.Buisclientrow.row {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #4f4f4f;
}
div.Addrowpre.row {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #4f4f4f;
}

div.Addrowpre.row p {
  font-weight: 600;
  font-size: 2.5vh !important;
  line-height: 2.5vh;
  letter-spacing: -0.408px;
  color: #333333;
  margin-bottom: 1.6vh !important;
}
.AddressPre .Addrowpre p.address-para {
  font-weight: 600;
    font-size: 2.2vh !important;
    font-family: lato;
    line-height: 4vh;
    text-align: justify;
    letter-spacing: 0px;
    color: #828282;
    margin-bottom: 5vh !important;
}

div.Busicol.col {
  margin-top: 3rem;
}
div.Addcol.col {
  margin-top: 5vh;
}
div.Clientspre.col img {
  /* width: 150px;
height: 150px; */
object-fit: contain;
  border: none !important;
  /* margin-top: 6rem;
margin-bottom: 2rem; */
}

div.Clientspre1.col {
  margin-top: 3rem;
  margin-right: -4rem;
}
.Clientspre1{
  font-weight: 500;
  font-size: 1.8vh !important;
  line-height: 2.2vh;
  letter-spacing: -0.408px;
  color: #4f4f4f;
}
li.ComapnyPreviewitems.nav-item {
  margin: 0 1.67vw 0 0;
  text-align: center;
}
.pro-le {
  margin-left: 0 !important;
}
p.Clientspre1 {
  margin-top: 5vh;
  margin-bottom: 0.8vh;
}
.side-col {
  margin-bottom: 20px;
}
.SumaryhePreview p{
  margin-bottom: 1rem !important;
  font-family: 'roboto','sans-serif';
}
.Clientspre .add-client-images4 {
  width: 10.42vw;
  height: 10.42vw;
  border: none !important;
  margin: 0;
  object-fit: contain;
}
.add-certificate-images1{
  width: 10.42vw;
  height: 10.42vw;
  border: none !important;
  margin: 0;
  object-fit: contain;
}
.certLogodiv1{
  width: 13.89vw;
  height: 10.42vw;
 
  font-weight: 500;
  font-size: 2.4vh !important;
  line-height: 2.9vh;
  letter-spacing: -0.408px;
  color: #4F4F4F !important;
  overflow: hidden;
  margin: 0 0 2.5vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-sec {
  display: grid;
  grid-template-columns: repeat(auto-fit, 13.89vw);
  grid-gap: 3vw;
  overflow: auto;
}
