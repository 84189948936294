/****main container***/
.Main {
  display: flex;
  justify-content: center;
}

.Card {
  height: auto;
  width: 698px;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 3rem;
}
/***fot top heading***/
.SignUp-heading {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4f4f4f;
  margin-top: 15px;
  font-family: "Lato", sans-serif;
}
.SignUpForm {
  margin: 1rem !important;
}
.headingSignUp {
  text-align: center !important;
  margin-top: 6rem !important;
  font-size: 24px;
  font-family: "Lato", sans-serif;
}
/***for firstname and lastname***/
#Firstname,
#lastname {
  /* width: 22.57vw !important ; */
}
/****for all input fields**/
.form-control {
  /* height: 40px !important; */
  /* width: 666px !important; */
  border-radius: 4px !important;
  color: #4f4f4f !important;
}

/****label***/
.label {
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  color: #4f4f4f !important;
}

/****for * in label***/
.required:after {
  content: " *";
  color: red;
}

/****Button**/
.btn {
  float: right !important;
  width: 150px;
  border-radius: 4px;
  margin-left: 10px;
  /* height: 41px !important; */
}
.btn-outline-primary {
  color: #2f80ed;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 0;
  letter-spacing: 0.02em;
  text-align: center;
}
/***Location Screen**/
select#exampleSelect.form-control option {
  margin: 40px;
  width: 10rem !important;
}
/***for Buisness button***/
.Buis-Button {
  padding-top: 3.2vh;
}
textarea#exampleAddress {
  width: 100% !important;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  /* overflow: hidden !important;  */
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 10px;
  background-color: white !important;
}
.card {
  border-radius: 10px !important;
}
.SignupModalContent,
.SignupModalContent .modal-content {
  background-color: transparent !important;
  box-shadow: none;
}
button.Modal-back-button.btn.btn-primary {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 700;
}
.ModalSignupBOdy .modal-body {
  padding-top: 30px;
}
@media (max-width: 767px) {
  .card {
    width: 100%;
  }
}
